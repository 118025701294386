import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Client } from "../models";

interface ClientsTableProps {
  clients: Array<Client>;
  deleteClient: (client: Client) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: 14,
      fontWeight: "bold",
    },
  })
);

const ClientsTable: React.FC<ClientsTableProps> = (props: ClientsTableProps) => {
  const classes = useStyles();
  const { clients } = props;
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className={classes.head}>Name</TableCell>
            <TableCell className={classes.head}>Reference</TableCell>
            <TableCell className={classes.head}>Address</TableCell>
            <TableCell className={classes.head}>Town</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((client: Client) => (
            <TableRow key={client.id} onClick={() => navigate(`/clients/${client.id}`)}>
              <TableCell>
                <Avatar aria-label="client">{client.name.charAt(0)}</Avatar>
              </TableCell>
              <TableCell>
                {client.name} {client.surname}
              </TableCell>
              <TableCell>{client.reference ?? "-"}</TableCell>
              <TableCell>
                {client.address}
                {client.addressLine2 ? " - " + client.addressLine2 : ""}
              </TableCell>
              <TableCell>{client.town}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="Delete Client"
                  onClick={() => props.deleteClient(client)}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientsTable;
