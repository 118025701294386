import React, { useEffect, useState, useReducer } from "react";
import { InputAdornment, TextField, Typography, Button, Theme, Grid, TextareaAutosize, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { getSettings, saveSettings } from "../Data/SettingsAPI";
import LoadingView from "../Components/LoadingView";
import { EmailTemplate, Settings as SettingModel } from "../models";
import { SettingsContext } from "../App";
import { Storage } from "aws-amplify";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

interface s3Response {
  key: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
      margin: "8px",
    },
    buttonContainer: {
      margin: "8px",
    },
  })
);

enum SettingsActions {
  UPDATE = "UPDATE",
  INITIALISE = "INIT",
  MILEAGE = "MILEAGE",
  RESET = "RESET",
  BANK_DETAILS = "BANK",
  COMPANY_DETAILS = "COMPANY",
  EMAIL = "EMAIL",
  EMAILTEMPLATE = "EMAILTEMPLATE",
  SAVE = "SAVE",
}

type Action = {
  type: SettingsActions;
  payload?: any;
};

interface SettingsState extends SettingModel {
  hasChanged: boolean;
  mileageString?: string;
  originalSettings?: SettingModel;
}

const Settings: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const context = React.useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();

  const formatDollarsToCents = (value: string) => {
    value = (value + "").replace(/[^\d.-]/g, "");
    if (value && value.includes(".")) {
      value = value.substring(0, value.indexOf(".") + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
  };

  const formatCurrency = (cost: number | undefined | null) => {
    if (!cost) {
      return "";
    }
    return (cost / 100).toFixed(2);
  };

  const initialState: SettingsState = { id: "new", hasChanged: false };
  const settingsReducer = (state: SettingsState, action: Action) => {
    switch (action.type) {
      case SettingsActions.UPDATE:
        return Object.assign({}, state, {
          ...state,
          ...action.payload,
          hasChanged: true,
        });
      case SettingsActions.INITIALISE:
        return Object.assign({}, state, {
          ...state,
          ...action.payload,
          mileageString: formatCurrency(action.payload.mileageCost),
          originalSettings: action.payload,
          hasChanged: false,
        });
      case SettingsActions.MILEAGE:
        return Object.assign({}, state, {
          ...state,
          mileageCost: formatDollarsToCents(action.payload),
          hasChanged: true,
          mileageString: action.payload,
        });
      case SettingsActions.BANK_DETAILS:
        const bankDetails = {
          ...state.bankDetails,
          ...action.payload,
        };
        return Object.assign({}, state, {
          ...state,
          bankDetails: bankDetails,
          hasChanged: true,
        });
      case SettingsActions.EMAIL:
        const emailSettings = {
          ...state.emailSettings,
          ...action.payload,
        };
        return Object.assign({}, state, {
          ...state,
          emailSettings: emailSettings,
          hasChanged: true,
        });
      case SettingsActions.EMAILTEMPLATE:
        let emailTemplates = [...(state.emailTemplates ?? [])];
        let templateIndex = emailTemplates.findIndex(
          (template) => template?.templateName === action.payload.templateName
        );
        if (templateIndex === -1) {
          templateIndex = emailTemplates.length;
        }
        const template = { ...emailTemplates[templateIndex], ...action.payload };
        emailTemplates[templateIndex] = template;
        return Object.assign({}, state, {
          ...state,
          emailTemplates: emailTemplates,
          hasChanged: true,
        });
      case SettingsActions.COMPANY_DETAILS:
        const companyDetails = {
          ...state.companyDetails,
          ...action.payload,
        };
        return Object.assign({}, state, {
          ...state,
          companyDetails: companyDetails,
          hasChanged: true,
        });
      case SettingsActions.RESET:
        return Object.assign({}, state, {
          ...state.originalSettings,
          hasChanged: false,
          mileageString: formatCurrency(state.originalSettings?.mileageCost),
        });
      case SettingsActions.SAVE:
        const newSettings = { ...state };
        //TODO fix this, probably shouldn't update something in here. It shows warning in console when saving settings
        context.updateSettings(newSettings);
        saveSettings(newSettings);
        return Object.assign({}, state, {
          ...state,
          originalSettings: state,
          hasChanged: false,
        });
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(settingsReducer, initialState);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const s = await getSettings();
        dispatch({ type: SettingsActions.INITIALISE, payload: s });
        //setSettings(s);
        //setOriginalSettings(s);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const selectedFile = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 25000) {
      enqueueSnackbar("File too big, logo should be no larger than 25MB ", { variant: "error" });
      return;
    }
    console.log(file);
    try {
      const resp = (await Storage.put(file.name, file, {
        level: "private",
      })) as s3Response;
      enqueueSnackbar("Logo Uploaded", { variant: "success" });
      console.log(resp.key);
      dispatch({
        type: SettingsActions.UPDATE,
        payload: { logoKey: resp.key },
      });
    } catch (err) {
      enqueueSnackbar("Failed to upload logo", { variant: "error" });
      console.log("Error uploading file: ", err);
    }
  };

  // useEffect(() => {
  //     setMileage(formatCurrency(settings.mileageCost));
  // }, [settings]);

  return (
    <div>
      {isLoading ? (
        <LoadingView />
      ) : (
        <div className={classes.root}>
          <div>
            <Typography variant="subtitle1">App Settings</Typography>
            <TextField
              id="mileage-cost"
              label="Mileage (per mile)"
              value={state.mileageString}
              onChange={(e) =>
                dispatch({
                  type: SettingsActions.MILEAGE,
                  payload: e.target.value,
                })
              }
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
              }}
            />
          </div>
          <br />
          <br />
          <Container maxWidth="sm">
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1">Company Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="company-name"
                  label="Company Name"
                  value={state.companyDetails?.name ?? ""}
                  onChange={(e) =>
                    dispatch({
                      type: SettingsActions.COMPANY_DETAILS,
                      payload: { name: e.target.value },
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="company-address"
                  label="Address Line 1"
                  value={state.companyDetails?.address ?? ""}
                  onChange={(e) =>
                    dispatch({
                      type: SettingsActions.COMPANY_DETAILS,
                      payload: { address: e.target.value },
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="company-address-line-2"
                  label="Address Line 2"
                  value={state.companyDetails?.addressLine2 ?? ""}
                  onChange={(e) =>
                    dispatch({
                      type: SettingsActions.COMPANY_DETAILS,
                      payload: { addressLine2: e.target.value },
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="company-address-town"
                  label="Town"
                  value={state.companyDetails?.town ?? ""}
                  onChange={(e) =>
                    dispatch({
                      type: SettingsActions.COMPANY_DETAILS,
                      payload: { town: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="company-address-region"
                  label="Region"
                  value={state.companyDetails?.county ?? ""}
                  onChange={(e) =>
                    dispatch({
                      type: SettingsActions.COMPANY_DETAILS,
                      payload: { county: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="company-address-postcode"
                  label="Post Code"
                  value={state.companyDetails?.postCode ?? ""}
                  onChange={(e) =>
                    dispatch({
                      type: SettingsActions.COMPANY_DETAILS,
                      payload: { postCode: e.target.value },
                    })
                  }
                />
              </Grid>
            </Grid>
          </Container>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Invoice Settings</Typography>
              <Typography variant="body1">The following settings are for information on your invoices:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="invoice-prefix"
                label="Invoice Prefix"
                value={state.invoicePrefix}
                onChange={(e) =>
                  dispatch({
                    type: SettingsActions.UPDATE,
                    payload: { invoicePrefix: e.target.value },
                  })
                }
              />
              <TextField
                id="invoice-number"
                label="Next Invoice Number"
                value={state.invoiceNumber}
                onChange={(e) =>
                  dispatch({
                    type: SettingsActions.UPDATE,
                    payload: { invoiceNumber: parseInt(e.target.value) },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                id="invoice-text"
                value={state.invoiceBlurb}
                placeholder="The text you would like to appear on your invoices"
                minRows={5}
                style={{ width: "40%" }}
                onChange={(e) =>
                  dispatch({
                    type: SettingsActions.UPDATE,
                    payload: { invoiceBlurb: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle2">Company Logo</Typography>
              <Typography variant="caption">The image will be scaled to approx. 100 x 35 px</Typography>
              <input
                id="btn-upload"
                name="btn-upload"
                accept="image/*"
                style={{ display: "none" }}
                type="file"
                onChange={selectedFile}
              />
              <Typography variant="caption">Current file: {state.logoKey}</Typography>
              <label htmlFor="btn-upload">
                <Button variant="outlined" component="span">
                  {!state.logoKey ? "Choose File" : "Choose New File"}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Bank Details</Typography>
              <Grid item xs={12} container direction="column">
                <Grid item>
                  <TextField
                    id="account-name"
                    label="Account Name"
                    value={state.bankDetails?.accountName ?? ""}
                    onChange={(e) =>
                      dispatch({
                        type: SettingsActions.BANK_DETAILS,
                        payload: { accountName: e.target.value },
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="bank-name"
                    label="Bank Name"
                    value={state.bankDetails?.bankName ?? ""}
                    onChange={(e) =>
                      dispatch({
                        type: SettingsActions.BANK_DETAILS,
                        payload: { bankName: e.target.value },
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="bank-sort-code"
                    label="Sort Code"
                    value={state.bankDetails?.sortCode ?? ""}
                    onChange={(e) =>
                      dispatch({
                        type: SettingsActions.BANK_DETAILS,
                        payload: { sortCode: e.target.value },
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="bank-account-number"
                    label="Account Number"
                    value={state.bankDetails?.accountNumber ?? ""}
                    onChange={(e) =>
                      dispatch({
                        type: SettingsActions.BANK_DETAILS,
                        payload: { accountNumber: e.target.value },
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">E-Mail Settings</Typography>
              <Typography variant="body1">The following settings are for sending invoices by E-Mail:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email-address"
                label="Your E-Mail Address"
                value={state.emailSettings?.email}
                onChange={(e) =>
                  dispatch({
                    type: SettingsActions.EMAIL,
                    payload: { email: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="invoice-email" id="invoice-email">
                  <Typography>Invoice Email</Typography>
                  <Tooltip title="When you create an invoice this email will be sent. It can be overridden when you select to send an invoice.">
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        id="email-subject"
                        label="E-Mail Subject"
                        value={state.emailSettings?.subject}
                        onChange={(e) =>
                          dispatch({
                            type: SettingsActions.EMAIL,
                            payload: { subject: e.target.value },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextareaAutosize
                        id="default-email-text"
                        value={state.emailSettings?.defaultText}
                        placeholder="The text you would like to appear in your E-Mails, you can edit this when sending specific E-Mails"
                        minRows={5}
                        style={{ width: "40%" }}
                        onChange={(e) =>
                          dispatch({
                            type: SettingsActions.EMAIL,
                            payload: { defaultText: e.target.value },
                          })
                        }
                      />
                      <Typography variant="caption" display="block" gutterBottom>
                        Use these tags to insert data into your email:
                      </Typography>
                      <Typography variant="caption" display="block" gutterBottom>
                        [client_name], [client_surname], [invoice_name], [invoice_total], [invoice_label]
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="invoice-void" id="invoice-void">
                  <Typography>Void Email Template</Typography>
                  <Tooltip title="When you void an invoice, this email will be sent">
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        id="email-subject"
                        label="E-Mail Subject"
                        value={state.emailTemplates?.find((t: EmailTemplate) => t.templateName === "void")?.subject}
                        onChange={(e) =>
                          dispatch({
                            type: SettingsActions.EMAILTEMPLATE,
                            payload: { templateName: "void", includeAttachment: true, subject: e.target.value },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextareaAutosize
                        id="default-email-text"
                        value={state.emailTemplates?.find((t: EmailTemplate) => t.templateName === "void")?.body}
                        placeholder="The text you would like to appear in your E-Mails when notifying a client of a voided invoice"
                        minRows={5}
                        style={{ width: "40%" }}
                        onChange={(e) =>
                          dispatch({
                            type: SettingsActions.EMAILTEMPLATE,
                            payload: { templateName: "void", includeAttachment: true, body: e.target.value },
                          })
                        }
                      />
                      <Typography variant="caption" display="block" gutterBottom>
                        Use these tags to insert data into your email:
                      </Typography>
                      <Typography variant="caption" display="block" gutterBottom>
                        [client_name], [client_surname], [invoice_name], [invoice_total], [invoice_label]
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button variant="outlined" color="primary" onClick={() => dispatch({ type: SettingsActions.RESET })}>
              Cancel
            </Button>
            <Button
              variant={state.hasChanged ? "contained" : "outlined"}
              color="primary"
              onClick={() => dispatch({ type: SettingsActions.SAVE })}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
