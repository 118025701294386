/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInvoices = /* GraphQL */ `
  mutation CreateInvoices(
    $startDate: String!
    $endDate: String!
    $clientIds: [String]!
    $userID: String
    $label: String
  ) {
    createInvoices(
      startDate: $startDate
      endDate: $endDate
      clientIds: $clientIds
      userID: $userID
      label: $label
    ) {
      id
      clientID
      invoiceName
      filePath
      startDate
      endDate
      subtotal
      owner
      label
      servicesRendered {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const sendInvoices = /* GraphQL */ `
  mutation SendInvoices(
    $invoiceIDs: [String]!
    $messageText: String
    $subject: String
  ) {
    sendInvoices(
      invoiceIDs: $invoiceIDs
      messageText: $messageText
      subject: $subject
    )
  }
`;
export const createServiceRendered = /* GraphQL */ `
  mutation CreateServiceRendered(
    $input: CreateServiceRenderedInput!
    $condition: ModelServiceRenderedConditionInput
  ) {
    createServiceRendered(input: $input, condition: $condition) {
      id
      clientID
      serviceID
      invoiceID
      date
      client {
        id
        name
        surname
        address
        addressLine2
        town
        county
        postCode
        email
        phoneNumber
        mobilePhone
        reference
        servicesUsed {
          items {
            id
            clientID
            serviceID
            invoiceID
            date
            client {
              id
              name
              surname
              address
              addressLine2
              town
              county
              postCode
              email
              phoneNumber
              mobilePhone
              reference
              servicesUsed {
                nextToken
                startedAt
                __typename
              }
              invoices {
                nextToken
                startedAt
                __typename
              }
              pet {
                nextToken
                startedAt
                __typename
              }
              defaultMileage
              outstandingBalance
              notes
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            service {
              id
              name
              cost
              type
              rendered {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            quantity
            mileage
            mileageCost
            serviceCost
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invoices {
          items {
            id
            clientID
            invoiceName
            filePath
            startDate
            endDate
            subtotal
            owner
            label
            servicesRendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pet {
          items {
            id
            clientID
            Name
            Type
            Breed
            Sex
            Neutered
            VaccinationDue
            Notes
            OffLead
            TermsAccepted
            vetID
            vet {
              id
              name
              phoneNumber
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultMileage
        outstandingBalance
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      service {
        id
        name
        cost
        type
        rendered {
          items {
            id
            clientID
            serviceID
            invoiceID
            date
            client {
              id
              name
              surname
              address
              addressLine2
              town
              county
              postCode
              email
              phoneNumber
              mobilePhone
              reference
              servicesUsed {
                nextToken
                startedAt
                __typename
              }
              invoices {
                nextToken
                startedAt
                __typename
              }
              pet {
                nextToken
                startedAt
                __typename
              }
              defaultMileage
              outstandingBalance
              notes
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            service {
              id
              name
              cost
              type
              rendered {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            quantity
            mileage
            mileageCost
            serviceCost
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      quantity
      mileage
      mileageCost
      serviceCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateServiceRendered = /* GraphQL */ `
  mutation UpdateServiceRendered(
    $input: UpdateServiceRenderedInput!
    $condition: ModelServiceRenderedConditionInput
  ) {
    updateServiceRendered(input: $input, condition: $condition) {
      id
      clientID
      serviceID
      invoiceID
      date
      client {
        id
        name
        surname
        address
        addressLine2
        town
        county
        postCode
        email
        phoneNumber
        mobilePhone
        reference
        servicesUsed {
          items {
            id
            clientID
            serviceID
            invoiceID
            date
            client {
              id
              name
              surname
              address
              addressLine2
              town
              county
              postCode
              email
              phoneNumber
              mobilePhone
              reference
              servicesUsed {
                nextToken
                startedAt
                __typename
              }
              invoices {
                nextToken
                startedAt
                __typename
              }
              pet {
                nextToken
                startedAt
                __typename
              }
              defaultMileage
              outstandingBalance
              notes
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            service {
              id
              name
              cost
              type
              rendered {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            quantity
            mileage
            mileageCost
            serviceCost
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invoices {
          items {
            id
            clientID
            invoiceName
            filePath
            startDate
            endDate
            subtotal
            owner
            label
            servicesRendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pet {
          items {
            id
            clientID
            Name
            Type
            Breed
            Sex
            Neutered
            VaccinationDue
            Notes
            OffLead
            TermsAccepted
            vetID
            vet {
              id
              name
              phoneNumber
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultMileage
        outstandingBalance
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      service {
        id
        name
        cost
        type
        rendered {
          items {
            id
            clientID
            serviceID
            invoiceID
            date
            client {
              id
              name
              surname
              address
              addressLine2
              town
              county
              postCode
              email
              phoneNumber
              mobilePhone
              reference
              servicesUsed {
                nextToken
                startedAt
                __typename
              }
              invoices {
                nextToken
                startedAt
                __typename
              }
              pet {
                nextToken
                startedAt
                __typename
              }
              defaultMileage
              outstandingBalance
              notes
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            service {
              id
              name
              cost
              type
              rendered {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            quantity
            mileage
            mileageCost
            serviceCost
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      quantity
      mileage
      mileageCost
      serviceCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteServiceRendered = /* GraphQL */ `
  mutation DeleteServiceRendered(
    $input: DeleteServiceRenderedInput!
    $condition: ModelServiceRenderedConditionInput
  ) {
    deleteServiceRendered(input: $input, condition: $condition) {
      id
      clientID
      serviceID
      invoiceID
      date
      client {
        id
        name
        surname
        address
        addressLine2
        town
        county
        postCode
        email
        phoneNumber
        mobilePhone
        reference
        servicesUsed {
          items {
            id
            clientID
            serviceID
            invoiceID
            date
            client {
              id
              name
              surname
              address
              addressLine2
              town
              county
              postCode
              email
              phoneNumber
              mobilePhone
              reference
              servicesUsed {
                nextToken
                startedAt
                __typename
              }
              invoices {
                nextToken
                startedAt
                __typename
              }
              pet {
                nextToken
                startedAt
                __typename
              }
              defaultMileage
              outstandingBalance
              notes
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            service {
              id
              name
              cost
              type
              rendered {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            quantity
            mileage
            mileageCost
            serviceCost
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invoices {
          items {
            id
            clientID
            invoiceName
            filePath
            startDate
            endDate
            subtotal
            owner
            label
            servicesRendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pet {
          items {
            id
            clientID
            Name
            Type
            Breed
            Sex
            Neutered
            VaccinationDue
            Notes
            OffLead
            TermsAccepted
            vetID
            vet {
              id
              name
              phoneNumber
              address
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultMileage
        outstandingBalance
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      service {
        id
        name
        cost
        type
        rendered {
          items {
            id
            clientID
            serviceID
            invoiceID
            date
            client {
              id
              name
              surname
              address
              addressLine2
              town
              county
              postCode
              email
              phoneNumber
              mobilePhone
              reference
              servicesUsed {
                nextToken
                startedAt
                __typename
              }
              invoices {
                nextToken
                startedAt
                __typename
              }
              pet {
                nextToken
                startedAt
                __typename
              }
              defaultMileage
              outstandingBalance
              notes
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            service {
              id
              name
              cost
              type
              rendered {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            quantity
            mileage
            mileageCost
            serviceCost
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      quantity
      mileage
      mileageCost
      serviceCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      surname
      address
      addressLine2
      town
      county
      postCode
      email
      phoneNumber
      mobilePhone
      reference
      servicesUsed {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoices {
        items {
          id
          clientID
          invoiceName
          filePath
          startDate
          endDate
          subtotal
          owner
          label
          servicesRendered {
            items {
              id
              clientID
              serviceID
              invoiceID
              date
              client {
                id
                name
                surname
                address
                addressLine2
                town
                county
                postCode
                email
                phoneNumber
                mobilePhone
                reference
                defaultMileage
                outstandingBalance
                notes
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              service {
                id
                name
                cost
                type
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              quantity
              mileage
              mileageCost
              serviceCost
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pet {
        items {
          id
          clientID
          Name
          Type
          Breed
          Sex
          Neutered
          VaccinationDue
          Notes
          OffLead
          TermsAccepted
          vetID
          vet {
            id
            name
            phoneNumber
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultMileage
      outstandingBalance
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      surname
      address
      addressLine2
      town
      county
      postCode
      email
      phoneNumber
      mobilePhone
      reference
      servicesUsed {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoices {
        items {
          id
          clientID
          invoiceName
          filePath
          startDate
          endDate
          subtotal
          owner
          label
          servicesRendered {
            items {
              id
              clientID
              serviceID
              invoiceID
              date
              client {
                id
                name
                surname
                address
                addressLine2
                town
                county
                postCode
                email
                phoneNumber
                mobilePhone
                reference
                defaultMileage
                outstandingBalance
                notes
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              service {
                id
                name
                cost
                type
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              quantity
              mileage
              mileageCost
              serviceCost
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pet {
        items {
          id
          clientID
          Name
          Type
          Breed
          Sex
          Neutered
          VaccinationDue
          Notes
          OffLead
          TermsAccepted
          vetID
          vet {
            id
            name
            phoneNumber
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultMileage
      outstandingBalance
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      surname
      address
      addressLine2
      town
      county
      postCode
      email
      phoneNumber
      mobilePhone
      reference
      servicesUsed {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoices {
        items {
          id
          clientID
          invoiceName
          filePath
          startDate
          endDate
          subtotal
          owner
          label
          servicesRendered {
            items {
              id
              clientID
              serviceID
              invoiceID
              date
              client {
                id
                name
                surname
                address
                addressLine2
                town
                county
                postCode
                email
                phoneNumber
                mobilePhone
                reference
                defaultMileage
                outstandingBalance
                notes
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              service {
                id
                name
                cost
                type
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              quantity
              mileage
              mileageCost
              serviceCost
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pet {
        items {
          id
          clientID
          Name
          Type
          Breed
          Sex
          Neutered
          VaccinationDue
          Notes
          OffLead
          TermsAccepted
          vetID
          vet {
            id
            name
            phoneNumber
            address
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultMileage
      outstandingBalance
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createPet = /* GraphQL */ `
  mutation CreatePet(
    $input: CreatePetInput!
    $condition: ModelPetConditionInput
  ) {
    createPet(input: $input, condition: $condition) {
      id
      clientID
      Name
      Type
      Breed
      Sex
      Neutered
      VaccinationDue
      Notes
      OffLead
      TermsAccepted
      vetID
      vet {
        id
        name
        phoneNumber
        address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updatePet = /* GraphQL */ `
  mutation UpdatePet(
    $input: UpdatePetInput!
    $condition: ModelPetConditionInput
  ) {
    updatePet(input: $input, condition: $condition) {
      id
      clientID
      Name
      Type
      Breed
      Sex
      Neutered
      VaccinationDue
      Notes
      OffLead
      TermsAccepted
      vetID
      vet {
        id
        name
        phoneNumber
        address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deletePet = /* GraphQL */ `
  mutation DeletePet(
    $input: DeletePetInput!
    $condition: ModelPetConditionInput
  ) {
    deletePet(input: $input, condition: $condition) {
      id
      clientID
      Name
      Type
      Breed
      Sex
      Neutered
      VaccinationDue
      Notes
      OffLead
      TermsAccepted
      vetID
      vet {
        id
        name
        phoneNumber
        address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createVet = /* GraphQL */ `
  mutation CreateVet(
    $input: CreateVetInput!
    $condition: ModelVetConditionInput
  ) {
    createVet(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateVet = /* GraphQL */ `
  mutation UpdateVet(
    $input: UpdateVetInput!
    $condition: ModelVetConditionInput
  ) {
    updateVet(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteVet = /* GraphQL */ `
  mutation DeleteVet(
    $input: DeleteVetInput!
    $condition: ModelVetConditionInput
  ) {
    deleteVet(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      address
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      name
      cost
      type
      rendered {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      name
      cost
      type
      rendered {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      name
      cost
      type
      rendered {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      clientID
      invoiceName
      filePath
      startDate
      endDate
      subtotal
      owner
      label
      servicesRendered {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      clientID
      invoiceName
      filePath
      startDate
      endDate
      subtotal
      owner
      label
      servicesRendered {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      clientID
      invoiceName
      filePath
      startDate
      endDate
      subtotal
      owner
      label
      servicesRendered {
        items {
          id
          clientID
          serviceID
          invoiceID
          date
          client {
            id
            name
            surname
            address
            addressLine2
            town
            county
            postCode
            email
            phoneNumber
            mobilePhone
            reference
            servicesUsed {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            invoices {
              items {
                id
                clientID
                invoiceName
                filePath
                startDate
                endDate
                subtotal
                owner
                label
                status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            pet {
              items {
                id
                clientID
                Name
                Type
                Breed
                Sex
                Neutered
                VaccinationDue
                Notes
                OffLead
                TermsAccepted
                vetID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            defaultMileage
            outstandingBalance
            notes
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          service {
            id
            name
            cost
            type
            rendered {
              items {
                id
                clientID
                serviceID
                invoiceID
                date
                quantity
                mileage
                mileageCost
                serviceCost
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          quantity
          mileage
          mileageCost
          serviceCost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      mileageCost
      invoiceBlurb
      bankDetails {
        bankName
        accountName
        sortCode
        accountNumber
        __typename
      }
      invoiceNumber
      invoicePrefix
      companyDetails {
        name
        address
        addressLine2
        town
        county
        postCode
        __typename
      }
      emailSettings {
        defaultText
        subject
        email
        __typename
      }
      logoKey
      owner
      emailTemplates {
        templateName
        subject
        body
        includeAttachment
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      mileageCost
      invoiceBlurb
      bankDetails {
        bankName
        accountName
        sortCode
        accountNumber
        __typename
      }
      invoiceNumber
      invoicePrefix
      companyDetails {
        name
        address
        addressLine2
        town
        county
        postCode
        __typename
      }
      emailSettings {
        defaultText
        subject
        email
        __typename
      }
      logoKey
      owner
      emailTemplates {
        templateName
        subject
        body
        includeAttachment
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      mileageCost
      invoiceBlurb
      bankDetails {
        bankName
        accountName
        sortCode
        accountNumber
        __typename
      }
      invoiceNumber
      invoicePrefix
      companyDetails {
        name
        address
        addressLine2
        town
        county
        postCode
        __typename
      }
      emailSettings {
        defaultText
        subject
        email
        __typename
      }
      logoKey
      owner
      emailTemplates {
        templateName
        subject
        body
        includeAttachment
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
