import { API, Auth, DataStore } from "aws-amplify";
import { CreateInvoicesMutation, SendInvoicesMutation } from "../API";
import { createInvoices, sendInvoices } from "../graphql/mutations";
import { Invoice, ServiceRendered } from "../models";

export const CreateInvoices = async (startDate: Date, endDate: Date, clientIds: Array<string>, label: string) => {
  const user = await Auth.currentCredentials();
  const resp = (await API.graphql({
    query: createInvoices,
    variables: {
      clientIds: clientIds,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      userID: user.identityId,
      label: label,
    },
  })) as { data: CreateInvoicesMutation };
  console.log("create response", resp);
  return;
};

export const SendInvoices = async (invoiceIDs: Array<string>, message: string, subject: string) => {
  console.log("sending invoices");
  const resp = (await API.graphql({
    query: sendInvoices,
    variables: { invoiceIDs: invoiceIDs, messageText: message, subject: subject },
  })) as { data: SendInvoicesMutation };
  console.log("send response", resp);
  return resp;
};

function formatDate(date: Date) {
  let month = "" + (date.getMonth() + 1),
    day = "" + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const DeleteInvoice = async (invoiceID: string) => {
  console.log("Deleting");
  const servicesRendered = await DataStore.query(ServiceRendered);
  for (const s of servicesRendered) {
    if (s.invoiceID === invoiceID) {
      await DataStore.save(
        ServiceRendered.copyOf(s, (updated) => {
          Object.assign(updated, {
            ...s,
            invoiceID: null,
          });
        })
      );
    }
  }
  await DataStore.delete(Invoice, invoiceID);
};

export const UpdateInvoice = async (invoice: Invoice): Promise<Invoice> => {
  console.log("Updating invoice");
  const original = await DataStore.query(Invoice, invoice.id);
  if (!original) {
    throw new Error("Invoice not found to update");
  }

  return await DataStore.save(
    Invoice.copyOf(original, (updated) => {
      Object.assign(updated, invoice);
    })
  );
};

export const CancelInvoice = async (invoice: Invoice): Promise<Invoice> => {
  console.log("Voiding invoice");
  const servicesRendered = await DataStore.query(ServiceRendered);
  for (const s of servicesRendered) {
    if (s.invoiceID === invoice.id) {
      await DataStore.save(
        ServiceRendered.copyOf(s, (updated) => {
          Object.assign(updated, {
            ...s,
            invoiceID: null,
          });
        })
      );
    }
  }
  return UpdateInvoice(invoice);
};
