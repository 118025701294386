import React, { FC } from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingProps { }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading_view: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center"
        }
    })
);

const LoadingView: FC<LoadingProps> = (props: LoadingProps) => {
    const classes = useStyles();
    return (<div className={classes.loading_view}>
        <CircularProgress />
    </div>);
}

export default LoadingView;
