import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { Client, Invoice, InvoiceStatus } from "../models";
import { Menu, MenuItem } from "@mui/material";
import ConfirmationAlert from "./ConfirmationAlert";

interface InvoiceActionsProps {
  clients: Array<Client>;
  selectedInvoices: Array<Invoice>;
  createInvoices: () => void;
  deleteInvoices: () => void;
  sendInvoices: () => void;
  markInvoicesAs: (status: InvoiceStatus) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "flex-wrap": "wrap",
      "justify-content": "center",
    },
  })
);

const InvoiceActions: React.FC<InvoiceActionsProps> = (props: InvoiceActionsProps) => {
  const classes = useStyles();
  const [selectedInvoices, setSelectedInvoices] = useState(props.selectedInvoices);
  const [createDisabled, setCreateDisabled] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [markAsDisabled, setMarkAsDisabled] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const [removeMarkAsCancelled, setRemoveMarkAsCancelled] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { createInvoices, deleteInvoices, sendInvoices, markInvoicesAs } = props;

  useEffect(() => {
    console.log(props.selectedInvoices);
    setSelectedInvoices(props.selectedInvoices);
    setCreateDisabled(false);
    setSendDisabled(false);
    setDeleteDisabled(false);
    setMarkAsDisabled(false);

    //Disable the create button if any selected have already been created
    if (props.selectedInvoices.filter((i) => i.status !== undefined).length > 0) {
      setCreateDisabled(true);
    }

    //Disable the send button if any selected aren't in the "CREATED" state
    if (
      props.selectedInvoices.filter((i) => i.status !== InvoiceStatus.CREATED && i.status !== InvoiceStatus.SENT)
        .length > 0
    ) {
      setSendDisabled(true);
    }

    // Disable the delete button if any selected have not been created
    if (props.selectedInvoices.filter((i) => i.status === undefined || i.status !== InvoiceStatus.CREATED).length > 0) {
      setDeleteDisabled(true);
      setRemoveMarkAsCancelled(true);
    }

    // Disable all actions if any have been voided
    if (
      props.selectedInvoices.filter(
        (i) =>
          i.status === InvoiceStatus.VOIDED ||
          i.status === InvoiceStatus.VOIDING ||
          i.status === InvoiceStatus.CANCELLED
      ).length > 0
    ) {
      setMarkAsDisabled(true);
    }

    if (props.selectedInvoices.filter((i) => i.status !== InvoiceStatus.SENT).length > 0) {
      setRemoveMarkAsCancelled(false);
    }
  }, [props]);

  const handleMarkAsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMarkAs = (status: InvoiceStatus) => {
    setMenuAnchorEl(null);
    if (status === InvoiceStatus.VOIDING) {
      setConfirmationOpen(true);
      return;
    }
    markInvoicesAs(status);
  };

  const confirmCancel = () => {
    markInvoicesAs(InvoiceStatus.VOIDING);
    setConfirmationOpen(false);
  };

  const rejectCancel = () => {
    setConfirmationOpen(false);
    return;
  };

  return (
    <div className={classes.root}>
      <ConfirmationAlert
        open={confirmationOpen}
        title="Are you sure you want to void this invoice?"
        message="Once voided you will have to create a new invoice. Do you wish to continue?"
        confirmFunction={confirmCancel}
        rejectFunction={rejectCancel}
      />
      <ButtonGroup
        color="secondary"
        variant="contained"
        aria-label="invoice actions"
        disabled={selectedInvoices.length === 0}
      >
        <Button onClick={createInvoices} disabled={createDisabled}>
          Create
        </Button>
        <Button onClick={sendInvoices} disabled={sendDisabled}>
          Send
        </Button>
        <Button onClick={deleteInvoices} disabled={deleteDisabled}>
          Delete
        </Button>
        <Button onClick={handleMarkAsClick} disabled={markAsDisabled}>
          Mark As...
        </Button>
      </ButtonGroup>
      <Menu
        id="mark-as-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem onClick={() => handleMarkAs(InvoiceStatus.PAID)}>PAID</MenuItem>
        {removeMarkAsCancelled && <MenuItem onClick={() => handleMarkAs(InvoiceStatus.VOIDING)}>VOIDED</MenuItem>}
        <MenuItem onClick={() => handleMarkAs(InvoiceStatus.SENT)}>SENT</MenuItem>
      </Menu>
    </div>
  );
};

export default InvoiceActions;
