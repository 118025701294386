import { Client, Pet } from "../models";
import { DataStore } from 'aws-amplify';

export const savePet = async(pet: Pet, isNew: Boolean): Promise<Pet> => {
    if (isNew){
        return addNewPet(pet);
    }else{
        return updatePet(pet);
    }
}

export const deletePet = async(petID: string) => {
    console.log("deleting pet", petID)
    await DataStore.delete(Pet, petID);
    //await DataStore.delete() //TODO fitgure out how to delete the pet from the client...even though it doesn't exist....
    // const deleteInput: DeletePetInput = {id: petID};
    // const deleteResult = await API.graphql({query: deletePetMutation, variables: { input: deleteInput}});
    // console.log("Delete pet result", deleteResult);
}

const addNewPet = async(pet: Pet): Promise<Pet> => {
    const input: Pet = {
        id: pet.id,
        Name: pet.Name,
        clientID: pet.clientID,
        Type: pet.Type,
        Breed: pet.Breed,
        Sex: pet.Sex,
        Neutered: pet.Neutered,
        VaccinationDue: pet.VaccinationDue,
        Notes: pet.Notes,
        OffLead: pet.OffLead,
        TermsAccepted: pet.TermsAccepted,
        vetID: pet.vetID,
    };

    console.log("saving pet", input);
    //TODO Change this to .then and .catch
    try{
    const petSaved = await DataStore.save(new Pet({
        ...pet
    }));
    console.log("petSaved", petSaved)
    const client = await DataStore.query(Client, pet.clientID);
        console.log("saving pet client", client)
        if (!client){
            throw Error("failed to save pet");
        }
        await DataStore.save(Client.copyOf(client, updated => {
            updated.pet?.push(petSaved);
        }))

        return petSaved;
} catch(e){
    console.log("failed to save pet", e)
    throw e
}

        


    // const petResponse = await DataStore.save(new Pet({...pet}));
    // const clientResponse = await DataStore.query(Client, pet.clientID);
    // if (!petResponse || !clientResponse){
    //     console.log("PetResponse", petResponse);
    //     console.log("clientResponse", clientResponse);
    //     throw "failed to create pet"
    // }

    // const clientUpdate = await DataStore.save(Client.copyOf(clientResponse, updated => {
    //     updated.pet?.push(pet);
    // }))
    // console.log("Updated client w pet", clientUpdate);
    // return petResponse;
    // const saveResult = (await API.graphql({query: createPet, variables: {input: input}})) as {data: CreatePetMutation };
    // console.log("save pet result", saveResult);
    
    // if(saveResult.data.createPet){
    //     return {...pet};
    // }

    // throw(new Error("Failed to save pet"));
}

const updatePet = async(pet: Pet): Promise<Pet> => {
    console.log("updating pet")
    const input: Pet = {
        id: pet.id,
        Name: pet.Name,
        clientID: pet.clientID,
        Type: pet.Type,
        Breed: pet.Breed,
        Sex: pet.Sex,
        Neutered: pet.Neutered,
        VaccinationDue: pet.VaccinationDue,
        Notes: pet.Notes,
        OffLead: pet.OffLead,
        TermsAccepted: pet.TermsAccepted,
        vetID: pet.vetID
    };

    const original = await DataStore.query(Pet, pet.id);
    if(!original){
        throw new Error("Pet not found to update");
    }

    return await DataStore.save(Pet.copyOf(original, updated => {
        Object.assign(updated, input);
    }))
    // const updateResult = (await API.graphql({ query: updateMutation, variables: { input: input}})) as { data: UpdatePetMutation};
    // console.log("Update pet result", updateResult);
    // if(updateResult.data.updatePet){
    //     return pet;
    // } else { 
    //     throw (new Error("Failed to update pet"));
    // }
}