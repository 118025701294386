import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PetsIcon from "@mui/icons-material/Pets";
import { Pet, Vet } from "../models";
import PetCard, { PetCardPet } from "./PetCard";
import { savePet as savePetAPI, deletePet as deletePetAPI } from "../Data/PetAPI";
import { useSnackbar } from "notistack";

interface PetContainerProps {
  pets: Array<Pet | null>;
  clientID: string;
  vets: Array<Vet>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBlock: {
      display: "flex",
      "justify-content": "space-between",
    },
    petContainer: {
      display: "flex",
      "flex-wrap": "wrap",
    },
    titleIcon: {
      display: "flex",
    },
  })
);

const PetContainer: React.FC<PetContainerProps> = (props: PetContainerProps) => {
  const classes = useStyles();
  const [allPets, setAllPets] = useState<Array<PetCardPet>>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let existingPets = props.pets as Array<PetCardPet>;
    let pettyPets = existingPets.map((p) => {
      let pet: PetCardPet;
      pet = { ...p };
      pet.isNew = false;
      pet.editable = false;
      return pet;
    });
    console.log(pettyPets);
    setAllPets(pettyPets);
  }, [props.pets]);

  const addPet = () => {
    const pet: PetCardPet = {
      id: uuidv4(),
      clientID: props.clientID,
      Name: "",
      editable: true,
      isNew: true,
      Type: "Dog",
      Neutered: true,
      Breed: "",
      VaccinationDue: "",
      Notes: "",
      OffLead: false,
      TermsAccepted: false,
    };
    setAllPets(allPets.concat(pet));
  };

  const savePet = async (pet: PetCardPet) => {
    console.log("saving", pet);
    try {
      const petID = pet.id;
      const savedPet = await savePetAPI(pet, pet.isNew);
      const pets: Array<PetCardPet> = Object.assign([], allPets);
      const index = allPets.findIndex((p) => p.id === petID);
      const newPet = { ...pet, ...savedPet, editable: false, isNew: false };
      pets[index] = newPet;
      setAllPets(pets);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to save pet", { variant: "error" });
    }
  };

  const deletePet = async (id: string, isNew: boolean) => {
    console.log("Deleting");
    try {
      await deletePetAPI(id);
      setAllPets(allPets.filter((p) => p.id !== id));
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to delete pet", { variant: "error" });
    }
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      //eslint-disable-next-line
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  return (
    <Paper variant="outlined" square>
      <div className={classes.titleBlock}>
        <div className={classes.titleIcon}>
          <PetsIcon />
          <Typography variant="h6">Pets</Typography>
        </div>
        <Button id="add-pet" aria-label="Add Pet" variant="contained" color="secondary" onClick={addPet}>
          Add Pet
        </Button>
      </div>
      <div className={classes.petContainer}>
        {allPets.map((pet) => (
          <PetCard key={pet.id} pet={pet} save={savePet} delete={deletePet} vets={props.vets} />
        ))}
      </div>
    </Paper>
  );
};

export default PetContainer;
