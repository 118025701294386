import React, { useState } from "react";
import { Card, Typography, CardContent, CardActions, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { DisplayableVet as Vet } from "../Data/Types";

interface VetCardProps {
  vet: Vet;
  saveVet: (Vet: Vet, isNew: boolean) => Promise<boolean>;
  deleteVet: (Vet: Vet) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "8px",
      display: "flex",
      "flex-direction": "row",
    },
    content: {
      display: "flex",
      "flex-direction": "row",
      "flex-grow": "3",
      "justify-content": "space-around",
    },
    mainContent: {
      margin: "8px",
    },
    actions: {
      "flex-grow": 1,
      "justify-content": "space-evenly",
    },
  })
);

const VetCard: React.FC<VetCardProps> = (props: VetCardProps) => {
  const classes = useStyles();
  const Vet = props.vet;
  const [isEditable, setEditable] = useState(Vet.editable ? Vet.editable : false);
  const [name, setName] = useState(Vet.name);
  const [isNew, setIsNew] = useState(Vet.name === "");
  const [phoneNumber, setPhoneNumber] = useState(Vet.phoneNumber);
  const [address, setAddress] = useState(Vet.address);

  const handleSave = async () => {
    setEditable(false);
    console.log("Saving");
    setIsNew(
      !(await props.saveVet(
        {
          id: Vet.id,
          name: name,
          phoneNumber: phoneNumber,
          address: address,
        },
        isNew
      ))
    );
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {!isEditable ? (
          <div>
            <Typography variant="caption" display="block" gutterBottom>
              Name
            </Typography>
            <Typography id="Name" className={classes.mainContent}>
              {name}
            </Typography>
          </div>
        ) : (
          <TextField
            id="name"
            label="Name"
            required
            className={classes.mainContent}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
          />
        )}
        {!isEditable ? (
          <div>
            <Typography variant="caption" display="block" gutterBottom>
              Phone Number
            </Typography>
            <Typography id="phoneNumber">{phoneNumber}</Typography>
          </div>
        ) : (
          <TextField
            id="phoneNumber"
            label="Phone Number"
            required
            value={phoneNumber}
            className={classes.mainContent}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
          />
        )}
        {!isEditable ? (
          <div>
            <Typography variant="caption" display="block" gutterBottom>
              Address
            </Typography>
            <Typography id="address">{address}</Typography>
          </div>
        ) : (
          <TextField
            id="address"
            label="Address"
            required
            value={address}
            className={classes.mainContent}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
          />
        )}
      </CardContent>
      <CardActions className={classes.actions}>
        {!isEditable ? (
          <IconButton
            aria-label="Edit Vet"
            color="primary"
            onClick={() => setEditable(true)}
            size="large">
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton aria-label="Save" color="primary" onClick={handleSave} size="large">
            <SaveIcon />
          </IconButton>
        )}
        <IconButton
          aria-label="Delete Vet"
          color="secondary"
          onClick={() => props.deleteVet(Vet)}
          size="large">
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default VetCard;
