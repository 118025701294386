import React, { useState, useEffect } from "react";
import LoadingView from "../Components/LoadingView";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { Client } from "../models/index";
import ClientCard from "../Components/ClientCard";
import ClientsTable from "../Components/ClientsTable";
import { deleteClient as deleteClientCall, loadClients } from "../Data/ClientAPI";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "flex-direction": "column",
    },
    actions: {
      display: "flex",
      "justify-content": "space-between",
      padding: "16px",
    },
    filter: {
      "margin-left": "auto",
      "flex-grow": 0.3,
    },
    cardContainer: {
      display: "flex",
      "flex-wrap": "wrap",
      "justify-content": "center",
    },
    addButton: {
      position: "fixed",
      [theme.breakpoints.down('md')]: {
        "z-index": 1100,
        top: "2.25em",
        left: "50%",
        transform: "translateX(-50%)",
      },
      [theme.breakpoints.up("sm")]: {
        bottom: "1em",
        right: "1em",
      },
    },
    noData: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      height: "100vh",
    },
    buttonGroup: {
      "margin-left": "auto",
    },
  })
);

const ClientsPage: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState<Array<Client>>([]);
  const [allClients, setAllClients] = useState<Array<Client>>([]);
  const [cardView, setCardView] = useState<boolean>(localStorage.getItem("cardView") === "false" ? false : true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line
  }, []);

  const fetchClients = async () => {
    try {
      const clientData = await loadClients();
      setClients(clientData);
      setAllClients(clientData);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to load clients", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const addClient = () => {
    navigate("/clients/new");
  };

  const deleteClient = async (client: Client) => {
    await deleteClientCall(client);
    enqueueSnackbar("Deleted client");
    setClients(clients.filter((c) => c.id !== client.id));
  };

  const switchView = () => {
    localStorage.setItem("cardView", (!cardView).toString());
    setCardView(!cardView);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = allClients.filter(
      (c) =>
        c.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()) ||
        c.surname.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()) ||
        c.reference?.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())
    );
    setClients(filtered);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingView />
      ) : (
        <div className={classes.root}>
          <div className={classes.actions}>
            <TextField
              className={classes.filter}
              onChange={handleFilterChange}
              id="filter"
              label="Filter Clients by Name or Reference"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <ButtonGroup className={classes.buttonGroup}>
              <Button onClick={switchView} disabled={cardView} variant={cardView ? "contained" : undefined}>
                <DashboardIcon />
              </Button>
              <Button disabled={!cardView} onClick={switchView} variant={cardView ? undefined : "contained"}>
                <ListIcon />
              </Button>
            </ButtonGroup>
          </div>
          <Paper className={classes.cardContainer}>
            {cardView ? (
              clients.map((c) => <ClientCard key={c.id} client={c} deleteClient={deleteClient} />)
            ) : (
              <ClientsTable clients={clients} deleteClient={deleteClient} />
            )}
            {!clients ||
              (clients.length === 0 && (
                <div className={classes.noData}>
                  <Typography variant="h6">You don't have any clients yet</Typography>
                  <Typography variant="subtitle1">Tap + to create one</Typography>
                </div>
              ))}
            <Fab color="secondary" aria-label="add" className={classes.addButton} onClick={addClient}>
              <AddIcon />
            </Fab>
          </Paper>
        </div>
      )}
    </div>
  );
};
export default ClientsPage;
