import React, { useEffect, useState } from "react";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import PetsIcon from "@mui/icons-material/Pets";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import LinkCard from "../Components/LinkCard";
import NewActivityPopup from "../Components/NewActivityPopup";
import { loadClients } from "../Data/ClientAPI";
import { loadActivities } from "../Data/ActivitiesApi";
import { SettingsContext } from "../App";

interface HomeProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "flex-wrap": "wrap",
      "justify-content": "center",
    },
    error: {
      fontSize: 12,
      color: "red",
    },
  })
);

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const classes = useStyles();
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null);
  const [errors, setErrors] = useState<Array<React.ReactNode>>([]);
  const { settings } = React.useContext(SettingsContext);
  const setTarget = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverElement(event.currentTarget);
  };

  const open = Boolean(popoverElement);

  useEffect(() => {
    if (!settings || settings.id === "new" || !settings.mileageCost) {
      console.log("settings", settings);
      const e = Object.assign([], errors);
      e.push(
        <span className={classes.error}>
          Please update your <a href="/settings">settings</a> before you adding activities
        </span>
      );
      setErrors(e);
    } else {
      setErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    const fetchObjects = async () => {
      const clients = await loadClients();
      const activities = await loadActivities();

      if (!clients || clients.length === 0) {
        const e = Object.assign([], errors);
        e.push(
          <span className={classes.error}>
            You must add some <a href="/clients">clients</a> before you can add activities
          </span>
        );
        setErrors(e);
      }

      if (!activities || activities.length === 0) {
        const e = Object.assign([], errors);
        e.push(
          <span className={classes.error}>
            You must add some <a href="/services">services</a> before you can add activities
          </span>
        );
        setErrors(e);
      }
    };
    fetchObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <LinkCard
        urlPath="activities/new"
        title="Add Activity"
        icon={<AddCircleIcon />}
        onClick={setTarget}
        errors={errors}
      />
      <LinkCard urlPath="clients" title="Clients" icon={<PeopleAltIcon />} />
      <LinkCard urlPath="services" title="Your Services" icon={<RoomServiceIcon />} />
      <LinkCard urlPath="invoices" title="Invoices" icon={<DescriptionIcon />} />
      <LinkCard urlPath="vets" title="Vets" icon={<PetsIcon />} />
      <NewActivityPopup
        isOpen={errors.length > 0 ? false : open}
        anchorEl={popoverElement}
        onClose={() => setPopoverElement(null)}
      />
    </div>
  );
};

export default Home;
