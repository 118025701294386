import React from "react";
import { useNavigate } from "react-router-dom";
import { Client } from "../models/index";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Avatar from "@mui/material/Avatar";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

interface ClientCardProps {
  client: Client;
  deleteClient: (client: Client) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "8px",
      "padding-top": "24px",
      [theme.breakpoints.up("sm")]: {
        width: "30%",
      },
      [theme.breakpoints.down('md')]: {
        width: "95%",
      },
    },
    actions: {
      "justify-content": "center",
    },
  })
);

const ClientCard: React.FC<ClientCardProps> = (props: ClientCardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const client = props.client;

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<Avatar aria-label="client">{client.name.charAt(0)}</Avatar>}
        title={
          <Typography variant="h5">
            {client.name} {client.surname}
          </Typography>
        }
        subheader={client.reference ? `Ref: ${client.reference}` : ""}
        onClick={() => navigate(`/clients/${client.id}`)}
      />
      <CardContent>
        <Typography variant="body2">{client.address}</Typography>
        {client.addressLine2 && <Typography variant="body2">{client.addressLine2}</Typography>}
        {client.town && <Typography variant="body2">{client.town}</Typography>}
        {client.county && <Typography variant="body2">{client.county}</Typography>}
        {client.postCode && <Typography variant="body2">{client.postCode}</Typography>}
      </CardContent>
      <CardActions className={classes.actions}>
        <IconButton
          aria-label="View Client"
          onClick={() => navigate(`/clients/${client.id}`)}
          size="large">
          <VisibilityIcon />
        </IconButton>
        <IconButton
          aria-label="Delete Client"
          onClick={() => props.deleteClient(client)}
          size="large">
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default ClientCard;
