import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { getClient, saveClient } from "../Data/ClientAPI";
import { Client, Vet } from "../models";
import LoadingView from "./LoadingView";
import { Theme, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import { useSnackbar } from "notistack";
import ActivityTable from "./ActivityTable";
import PetContainer from "./PetContainer";
import InvoiceTable from "./InvoiceTable";
import { Invoice } from "../models";
import { LoadVets } from "../Data/VetApi";
import ClientAddressPopup from "./ClientAddressPopup";
import { Address } from "../Data/Types";
import { Alert } from "@mui/material";
import NewActivityPopup from "./NewActivityPopup";

interface ClientDetailsProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addressBlock: {
      whiteSpace: "pre-line",
    },
    addressSection: {
      margin: "16px",
    },
    emailBlock: {},
    phoneBlock: {},
    detailSection: {
      display: "flex",
      "justify-content": "center",
      "flex-direction": "column",
    },
  })
);

const ClientDetails: React.FC<ClientDetailsProps> = (props: ClientDetailsProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [client, setClient] = useState<Client>();
  const [vets, setVets] = useState<Array<Vet>>([]);
  const [isNew, setIsNew] = useState(false);
  const [editAddressOpen, setEditAddressOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [editable, setEditable] = useState("");
  const [originalValue, setOriginalValue] = useState("");
  const [shouldShowAlert, showAlert] = useState(false);
  const [defaultMileage, setDefaultMileage] = useState("");
  const [notesText, setNotesText] = useState("");
  const { id } = useParams();

  const [filterInvoicedActivities, setFilterInvoicedActivities] = useState<boolean>(true);

  const [popoverElement, setPopoverElement] = useState<HTMLButtonElement | null>(null);
  const setTarget = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverElement(event.currentTarget);
  };

  const addActivityOpen = Boolean(popoverElement);

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (id !== "new") {
        setClient(await getClient(id!));
      } else {
        setClient({} as Client);
        setIsNew(true);
      }
    };
    fetchClientDetails();

    const fetchVets = async () => {
      const vetsResponse = await LoadVets();
      setVets(vetsResponse);
    };
    fetchVets();
  }, [id]);

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (id !== "new") {
        setClient(await getClient(id!));
      }
    };
    fetchClientDetails();
    // Don't need to change on Id change (I don't think!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverElement, id]);

  useEffect(() => {
    setDefaultMileage(client?.defaultMileage?.toFixed(2).toString() ?? "");
    setNotesText(client?.notes ?? "");
  }, [client]);

  const save = async () => {
    console.log(client);
    if (!client || !client.name || !client.address) {
      showAlert(true);
      return;
    }
    enqueueSnackbar("Saving client");
    const resp: Client = await saveClient({ ...client, defaultMileage: parseFloat(defaultMileage) });
    enqueueSnackbar("Saved client");
    setIsNew(false);
    setEditable("");
    navigate(`/clients/${resp.id}`);
  };

  const handleAddressClose = async (address: Address | undefined) => {
    setEditAddressOpen(false);
    if (address && client) {
      const c = {
        ...client,
        address: address.address ?? "",
        addressLine2: address.addressLine2 ?? "",
        town: address.town ?? "",
        county: address.county ?? "",
        postCode: address.postCode ?? "",
      };
      setClient(c);
      if (!isNew) {
        enqueueSnackbar("Saving client");
        await saveClient(c);
        enqueueSnackbar("Saved client");
        setIsNew(false);
      }
      setEditable("");
    }
  };

  const handleNotesChange = async () => {
    if (client && !isNew) {
      const c = {
        ...client,
        notes: notesText,
      };
      setClient(c);
      await saveClient(c);
    }
  };

  return (
    <>
      {client ? (
        <Grid container spacing={4}>
          <IconButton onClick={() => navigate(-1)} size="large">
            <ArrowBackIcon />
          </IconButton>
          <Grid item xs={12}>
            {shouldShowAlert && <Alert severity="error">Client name and address are required</Alert>}
          </Grid>
          <Grid item xs={12}>
            {isNew || editable === "name" ? (
              <FormControl>
                <InputLabel htmlFor="name">Forename</InputLabel>
                <Input
                  required
                  id="name"
                  value={client.name}
                  onChange={(e) => {
                    setClient({ ...client, name: e.target.value });
                    showAlert(false);
                  }}
                  endAdornment={
                    !isNew && (
                      <InputAdornment position="end">
                        <IconButton aria-label="save change" onClick={save} edge="end" size="large">
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          aria-label="cancel change"
                          onClick={() => {
                            setEditable("");
                            setClient({ ...client, name: originalValue });
                          }}
                          edge="end"
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            ) : (
              <div className={classes.detailSection}>
                <Typography variant="h3">{client.name}</Typography>
              </div>
            )}
            {isNew || editable === "name" ? (
              <FormControl>
                <InputLabel htmlFor="name">Surname</InputLabel>
                <Input
                  required
                  id="surname"
                  value={client.surname}
                  onChange={(e) => {
                    setClient({ ...client, surname: e.target.value });
                    showAlert(false);
                  }}
                  endAdornment={
                    !isNew && (
                      <InputAdornment position="end">
                        <IconButton aria-label="save change" onClick={save} edge="end" size="large">
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          aria-label="cancel change"
                          onClick={() => {
                            setEditable("");
                            setClient({ ...client, surname: originalValue });
                          }}
                          edge="end"
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            ) : (
              <div className={classes.detailSection}>
                <Typography variant="h3">{client.surname}</Typography>
                <IconButton
                  aria-label="edit-surname"
                  size="small"
                  onClick={() => {
                    setEditable("name");
                    setOriginalValue(client.surname);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={isNew ? 12 : 6}>
            <Grid xs item className={classes.addressBlock}>
              {isNew || editable === "reference" ? (
                <FormControl>
                  <InputLabel htmlFor="reference">Reference</InputLabel>
                  <Input
                    rows={5}
                    id="reference"
                    value={client.reference}
                    onChange={(e) => setClient({ ...client, reference: e.target.value })}
                    endAdornment={
                      !isNew && (
                        <InputAdornment position="end">
                          <IconButton aria-label="save change" onClick={save} edge="end" size="large">
                            <DoneIcon />
                          </IconButton>
                          <IconButton
                            aria-label="cancel change"
                            onClick={() => {
                              setEditable("");
                              setClient({
                                ...client,
                                reference: originalValue,
                              });
                            }}
                            edge="end"
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              ) : (
                <div className={classes.detailSection}>
                  <div>
                    <Typography variant="subtitle1">Reference</Typography>
                    <Typography variant="body2">{client.reference ?? "-"}</Typography>
                  </div>
                  <IconButton
                    aria-label="edit-reference"
                    size="small"
                    onClick={() => {
                      setEditable("reference");
                      setOriginalValue(client.reference ?? "");
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              )}
            </Grid>

            <Grid container justifyContent="center" alignItems="center" className={classes.addressSection}>
              <Grid item container xs={12} direction="row" justifyContent="center">
                <Grid item>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Address Details
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="edit-address"
                    size="small"
                    onClick={() => {
                      setEditAddressOpen(true);
                      showAlert(false);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid xs={6} item className={classes.addressBlock}>
                <div className={classes.detailSection}>
                  <Typography variant="subtitle1">Address</Typography>
                  <Typography variant="body2">{client.address}</Typography>
                </div>
              </Grid>
              <Grid xs={6} item className={classes.addressBlock}>
                <div className={classes.detailSection}>
                  <Typography variant="subtitle1">Address Line 2</Typography>
                  <Typography variant="body2">{client.addressLine2}</Typography>
                </div>
              </Grid>
              <Grid xs={3} item className={classes.addressBlock}>
                <div className={classes.detailSection}>
                  <Typography variant="subtitle1">Town</Typography>
                  <Typography variant="body2">{client.town}</Typography>
                </div>
              </Grid>
              <Grid xs={3} item className={classes.addressBlock}>
                <div className={classes.detailSection}>
                  <Typography variant="subtitle1">County</Typography>
                  <Typography variant="body2">{client.county}</Typography>
                </div>
              </Grid>
              <Grid xs={3} item className={classes.addressBlock}>
                <div className={classes.detailSection}>
                  <Typography variant="subtitle1">Post Code</Typography>
                  <Typography variant="body2">{client.postCode}</Typography>
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  Contact Details
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.emailBlock}>
                {isNew || editable === "phone" ? (
                  <FormControl>
                    <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                    <Input
                      id="phoneNumber"
                      value={client.phoneNumber ? client.phoneNumber : ""}
                      onChange={(e) => setClient({ ...client, phoneNumber: e.target.value })}
                      endAdornment={
                        !isNew && (
                          <InputAdornment position="end">
                            <IconButton aria-label="save change" onClick={save} edge="end" size="large">
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="cancel change"
                              onClick={() => {
                                setEditable("");
                                setClient({
                                  ...client,
                                  phoneNumber: originalValue,
                                });
                              }}
                              edge="end"
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  </FormControl>
                ) : (
                  <div className={classes.detailSection}>
                    <div>
                      <Typography variant="subtitle1">Phone Number</Typography>
                      <Typography variant="body2">{client.phoneNumber}</Typography>
                    </div>
                    <IconButton
                      aria-label="edit-phonenumber"
                      size="small"
                      onClick={() => {
                        setEditable("phone");
                        setOriginalValue(client.phoneNumber ?? "");
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </Grid>
              <Grid item xs={6} className={classes.emailBlock}>
                {isNew || editable === "mobile" ? (
                  <FormControl>
                    <InputLabel htmlFor="mobileNumber">Mobile Number</InputLabel>
                    <Input
                      id="mobileNumber"
                      value={client.mobilePhone ? client.mobilePhone : ""}
                      onChange={(e) => setClient({ ...client, mobilePhone: e.target.value })}
                      endAdornment={
                        !isNew && (
                          <InputAdornment position="end">
                            <IconButton aria-label="save change" onClick={save} edge="end" size="large">
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="cancel change"
                              onClick={() => {
                                setEditable("");
                                setClient({
                                  ...client,
                                  mobilePhone: originalValue,
                                });
                              }}
                              edge="end"
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  </FormControl>
                ) : (
                  <div className={classes.detailSection}>
                    <div>
                      <Typography variant="subtitle1">Mobile Number</Typography>
                      <Typography variant="body2">{client.mobilePhone}</Typography>
                    </div>
                    <IconButton
                      aria-label="edit-mobileNumber"
                      size="small"
                      onClick={() => {
                        setEditable("mobile");
                        setOriginalValue(client.mobilePhone ?? "");
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </Grid>
              <Grid item xs={6} className={classes.emailBlock}>
                {isNew || editable === "email" ? (
                  <FormControl>
                    <InputLabel htmlFor="emailAddress">Email Address</InputLabel>
                    <Input
                      required
                      id="emailAddress"
                      value={client.email ? client.email : ""}
                      onChange={(e) => setClient({ ...client, email: e.target.value })}
                      endAdornment={
                        !isNew && (
                          <InputAdornment position="end">
                            <IconButton aria-label="save change" onClick={save} edge="end" size="large">
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="cancel change"
                              onClick={() => {
                                setEditable("");
                                setClient({ ...client, email: originalValue });
                              }}
                              edge="end"
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  </FormControl>
                ) : (
                  <div className={classes.detailSection}>
                    <div>
                      <Typography variant="subtitle1">Email Address</Typography>
                      <Typography variant="body2">{client.email}</Typography>
                    </div>
                    <IconButton
                      aria-label="edit-emailaddress"
                      size="small"
                      onClick={() => {
                        setEditable("email");
                        setOriginalValue(client.email ?? "");
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  Mileage
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.emailBlock}>
                {isNew || editable === "defaultMileage" ? (
                  <FormControl>
                    <InputLabel htmlFor="defaultMileage">Default Mileage Distance</InputLabel>
                    <Input
                      required
                      type="number"
                      id="defaultMileage"
                      value={defaultMileage}
                      onChange={(e) => {
                        setDefaultMileage(e.target.value);
                      }}
                      endAdornment={
                        !isNew && (
                          <InputAdornment position="end">
                            <IconButton aria-label="save change" onClick={save} edge="end" size="large">
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="cancel change"
                              onClick={() => {
                                setEditable("");
                                setClient({ ...client, defaultMileage: parseFloat(originalValue) });
                              }}
                              edge="end"
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                  </FormControl>
                ) : (
                  <div className={classes.detailSection}>
                    <div>
                      <Typography variant="subtitle1">Default Mileage Distance</Typography>
                      <Typography variant="body2">{defaultMileage}</Typography>
                    </div>
                    <IconButton
                      aria-label="edit-defaultmileage"
                      size="small"
                      onClick={() => {
                        setEditable("defaultMileage");
                        setOriginalValue(defaultMileage ?? "");
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                  Notes
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="notes"
                  label="Notes"
                  multiline
                  minRows={4}
                  maxRows={4}
                  style={{ width: "80%" }}
                  value={notesText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNotesText(event.target.value);
                  }}
                  onBlur={handleNotesChange}
                />
              </Grid>
            </Grid>
          </Grid>
          {!isNew && (
            <Grid item xs={12} sm={6}>
              <PetContainer pets={client.pet ?? []} vets={vets} clientID={client.id} />
            </Grid>
          )}
          {!isNew && (
            <Grid item xs={11}>
              <Typography variant="h6">Invoices</Typography>
              {client.invoices && client.invoices.length > 0 ? (
                <InvoiceTable
                  clients={[client]}
                  clientInvoices={client.invoices as Array<Invoice>}
                  update={() => {}}
                  setSelectedInvoices={() => {}}
                  selectedInvoices={[]}
                  disableExpand
                />
              ) : (
                <Typography variant="body1">No Invoices</Typography>
              )}
            </Grid>
          )}
          {!isNew && (
            <Grid item xs={12}>
              <Grid item xs={12} container direction="row">
                <Grid item xs={7}>
                  <Typography variant="h6">Activities</Typography>
                </Grid>
                <Grid item xs={5} direction="row" spacing={1}>
                  <Button
                    startIcon={<FilterListIcon />}
                    onClick={() => setFilterInvoicedActivities(!filterInvoicedActivities)}
                  >
                    {filterInvoicedActivities ? "Show" : "Hide"} Invoiced Activities
                  </Button>
                  <Button variant="contained" color="secondary" onClick={setTarget}>
                    Add Activity
                  </Button>
                </Grid>
              </Grid>
              {client.servicesUsed && client.servicesUsed.length > 0 ? (
                <ActivityTable
                  data={
                    filterInvoicedActivities ? client.servicesUsed.filter((s) => !s?.invoiceID) : client.servicesUsed
                  }
                  clientId={client.id}
                />
              ) : (
                <Typography variant="body1">No Activities</Typography>
              )}
            </Grid>
          )}
          {isNew && (
            <Grid item xs={2}>
              <Button onClick={save} variant="contained" color="primary">
                Save
              </Button>
            </Grid>
          )}
          <ClientAddressPopup open={editAddressOpen} handleClose={handleAddressClose} client={client} />
          <NewActivityPopup
            isOpen={addActivityOpen}
            anchorEl={popoverElement}
            onClose={() => setPopoverElement(null)}
            clientID={client.id}
          />
        </Grid>
      ) : (
        <LoadingView />
      )}
    </>
  );
};

export default ClientDetails;
