import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

interface InvoiceLabelDialogProps {
  confirmFunction: (label: string) => void;
  rejectFunction: (emptyLabel: string) => void;
  open: boolean;
  multiple: boolean;
}

const InvoiceLabelDialog: React.FC<InvoiceLabelDialogProps> = (props: InvoiceLabelDialogProps) => {
  const { open, rejectFunction, confirmFunction, multiple } = props;
  const date = new Date();
  const [label, setLabel] = useState(date.toLocaleString("default", { month: "long" }) + " " + date.getFullYear());

  const getDescription = (): string => {
    if (multiple) {
      return "A label can be used to help identify invoices, for example the month they are for.";
    } else {
      return "A label can be used to help identify the invoice, for example the month it is for.";
    }
  };

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle id="invoice-label-dialog">Add a label to the {multiple ? "invoices" : "invoice"}?</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">{getDescription()}</Typography>
        <TextField id="invoice-label" label="Invoice Label" value={label} onChange={(e) => setLabel(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => rejectFunction("")}>No</Button>
        <Button color="primary" onClick={() => confirmFunction(label)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceLabelDialog;
