import React, { useState, useContext, useEffect } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ServiceRendered } from "../models";
import { UpdateActivityRendered, DeleteActivityRendered, loadActivities } from "../Data/ActivitiesApi";
import { SettingsContext } from "../App";
import { useSnackbar } from "notistack";
import { Service } from "../Data/Types";

interface ActivityTableProps {
  data: Array<ServiceRendered | null> | null | undefined;
  clientId: string;
  updateSource?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRoot: {
      margin: "16px",
      width: "90%",
    },
  })
);

interface DisplayActivityRow {
  clientID: string;
  serviceID: string;
  date: string;
  quantity: number;
  mileage: number;
  id: string;
  serviceCost: number;
  mileageTotal?: number | null;
  total: number;
  serviceName: string;
  invoiceId?: string | null;
}

const getDOW = (date: string): string => {
  const d = new Date(date);
  const days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
  const dayNumber = d.getDay();
  return (
    days[dayNumber] +
    " " +
    d.getDate() +
    "-" +
    (d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) +
    "-" +
    d.getFullYear()
  );
};

const ActivityTable: React.FC<ActivityTableProps> = (props: ActivityTableProps) => {
  const classes = useStyles();
  const [newRows, setNewRows] = useState<Array<DisplayActivityRow>>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [services, setServices] = useState<Array<Service>>([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await loadActivities();
        const sortedActivities = response.sort((a, b) => (a.name < b.name ? -1 : 1));
        setServices(sortedActivities);
      } catch (e) {
        enqueueSnackbar("Failed to load services", { variant: "error" });
      }
    };
    fetchServices();
  }, [enqueueSnackbar]);

  useEffect(() => {
    let r: Array<DisplayActivityRow> | undefined = props.data
      ?.filter((x) => x !== null)
      .map((x) => {
        const row: DisplayActivityRow = {
          clientID: props.clientId,
          serviceID: x!.service.id,
          date: x!.date,
          serviceName: x!.service.name,
          quantity: x!.quantity,
          mileage: x!.mileage ? x!.mileage : 0,
          id: x!.id,
          serviceCost: x!.serviceCost ?? x!.service.cost,
          mileageTotal: x!.mileageCost,
          invoiceId: x!.invoiceID,
          total: (x!.serviceCost ?? x!.service.cost) * (x!.quantity ?? 1) + (x!.mileageCost ?? 0),
        };
        return row;
      });
    r?.sort((a, b) => (a && b ? Date.parse(a.date) - Date.parse(b.date) : !a ? 1 : -1));

    setNewRows(r ?? []);
  }, [props]);

  const deleteRow = (id: string) => {
    if (!newRows) {
      return;
    }
    DeleteActivityRendered(id)
      .then(() => {
        setNewRows(newRows.filter((i) => i.id !== id));
        if (props.updateSource) {
          props.updateSource();
        }
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar("Failed to delete activity", {
          variant: "error",
        });
      });
  };

  return (
    <TableContainer component={Paper} className={classes.tableRoot}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Service</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Mileage</TableCell>
            <TableCell>Total Mileage</TableCell>
            <TableCell>Cost Per Activity</TableCell>
            <TableCell>Total Cost</TableCell>
            <TableCell>Invoiced?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newRows.map((row: DisplayActivityRow) => (
            <EditableTableRow
              key={row.id}
              {...row}
              delete={deleteRow}
              updateSource={props.updateSource}
              services={services}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EditableTableRow: React.FC<any> = (props) => {
  const [isEditable, setEditable] = useState(false);
  const [data, setData] = useState(Object.assign({}, props));
  const [original, setOriginal] = useState(Object.assign({}, props));

  const { settings } = useContext(SettingsContext);

  const save = async () => {
    const mileageCost = data.mileage * (settings?.mileageCost ?? 0);
    await UpdateActivityRendered(
      data.id,
      data.date,
      data.clientID,
      data.serviceID,
      data.mileage,
      data.quantity,
      mileageCost,
      data.serviceCost
    );
    setData({
      ...data,
      mileageTotal: mileageCost,
      total: data.quantity * data.serviceCost + mileageCost,
    });
    setOriginal({ ...data, mileageTotal: mileageCost, total: data.quantity * data.serviceCost + mileageCost });
    setEditable(false);
    if (props.updateSource) {
      props.updateSource();
    }
  };

  const updateSelectedService = (e: SelectChangeEvent) => {
    const newService = props.services.find((s: Service) => s.id === e.target.value);
    setData({
      ...data,
      serviceID: e.target.value as string,
      serviceName: newService.name,
      serviceCost: newService.cost,
    });
  };

  useEffect(() => {
    const mileageCost = (settings?.mileageCost ?? 0) * data.mileage;
    setData((d: any) => {
      return {
        ...d,
        mileageTotal: mileageCost,
        total: data.serviceCost * (data.quantity ?? 0) + Math.round(mileageCost),
      };
    });
  }, [data.mileage, data.serviceCost, data.quantity, settings]);

  return (
    <TableRow>
      {!isEditable ? (
        <>
          <TableCell component="th" scope="row">
            {getDOW(data.date)}
          </TableCell>
          <TableCell>{data.serviceName}</TableCell>
          <TableCell>{data.quantity}</TableCell>
          <TableCell>{data.mileage}</TableCell>
          <TableCell>£{formatCurrency(data.mileageTotal ?? 0)}</TableCell>
          <TableCell>£{formatCurrency(data.serviceCost)}</TableCell>
          <TableCell>£{formatCurrency(data.total)}</TableCell>
          <TableCell>
            {data.invoiceId ? <DoneIcon aria-label="invoiced-yes" /> : <CloseIcon aria-label="invoiced-no" />}
          </TableCell>
          <TableCell>
            <IconButton disabled={data.invoiceId} onClick={() => setEditable(true)} size="large">
              <EditIcon />
            </IconButton>
            <IconButton disabled={data.invoiceId} onClick={() => props.delete(data.id)} size="large">
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell component="th" scope="row">
            {getDOW(data.date)}
          </TableCell>
          <TableCell>
            <Select value={data.serviceID} onChange={updateSelectedService}>
              {props.services.map((s: Service, key: number) => (
                <MenuItem key={key} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell>
            <TextField
              value={data.quantity}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({ ...data, quantity: e.target.value })}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={data.mileage}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData({ ...data, mileage: e.target.value })}
            />
          </TableCell>
          <TableCell>£{formatCurrency(data.mileageTotal ?? 0)}</TableCell>
          <TableCell>£{formatCurrency(data.serviceCost)}</TableCell>
          <TableCell>£{formatCurrency(data.total)}</TableCell>
          <TableCell></TableCell>
          <TableCell>
            <IconButton onClick={save} size="large">
              <SaveIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditable(false);
                setData(original);
              }}
              size="large"
            >
              <CancelIcon />
            </IconButton>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const formatCurrency = (cost: number) => {
  return (cost / 100).toFixed(2);
};

export default ActivityTable;
