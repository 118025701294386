import { DataStore } from "aws-amplify";
import { Service } from "./Types";
import { Client, Service as AmpService, ServiceRendered } from "../models";
import { format } from "date-fns";

export const loadActivities = async (): Promise<Array<Service>> => {
  return await DataStore.query(AmpService);
  // const response = (await API.graphql({ query: listServices })) as {
  //   data: ListServicesQuery;
  // };
  // return (
  //   response.data?.listServices?.items?.map(
  //     (i) =>
  //       ({
  //         id: i?.id,
  //         name: i?.name,
  //         cost: i?.cost,
  //       } as Service)
  //   ) || []
  // );
};

export const SaveActivity = async (service: Service): Promise<AmpService> => {
  const response = await DataStore.save(new AmpService({ ...service }));
  return response;
};

export const UpdateActivity = async (service: Service) => {
  const original = await DataStore.query(AmpService, service.id);
  console.log("Original", original);
  if (!original) {
    throw new Error("Failed to find service to update");
  }
  console.log("Updating", service);
  await DataStore.save(
    AmpService.copyOf(original, (updated) => {
      Object.assign(updated, service);
    })
  );
};

export const DeleteActivity = async (id: string) => {
  await DataStore.delete(AmpService, id);
};

export const saveActivityRendered = async (
  date: Date,
  clientID: string,
  serviceID: string,
  mileage: string,
  quantity: string,
  mileageCost: number
): Promise<void> => {
  // const activity: CreateServiceRenderedInput = {
  //   date: format(date, "yyyy-MM-dd"),
  //   clientID: clientID,
  //   serviceID: serviceID,
  //   mileage: Number(mileage),
  //   quantity: Number(quantity),
  //   mileageCost: Math.round((mileageCost + Number.EPSILON) * 100) / 100,
  // };
  console.log("Creating activity");

  const service = await DataStore.query(AmpService, serviceID);
  const client = await DataStore.query(Client, clientID);

  if (!client || !service) {
    throw new Error("Unable to find client or service for this activity");
  }

  await DataStore.save(
    new ServiceRendered({
      date: format(date, "yyyy-MM-dd"),
      client: client,
      service: service,
      mileage: parseFloat(mileage),
      quantity: Number(quantity),
      mileageCost: Math.round(mileageCost),
      serviceCost: service.cost,
    })
  );
  // await API.graphql({
  //   query: createServiceRendered,
  //   variables: { input: activity },
  // });
};

export const UpdateActivityRendered = async (
  id: string,
  date: string,
  clientID: string,
  serviceID: string,
  mileage: string,
  quantity: string,
  mileageCost: number,
  serviceCost: number
): Promise<void> => {
  // const activity: ServiceRendered = {
  //   id: id,
  //   date: date,
  //   mileage: Number(mileage),
  //   quantity: Number(quantity),
  //   mileageCost: Math.round((mileageCost + Number.EPSILON)),
  // };
  const original = await DataStore.query(ServiceRendered, id);
  if (!original) {
    throw new Error("Failed to find service rendered to update");
  }
  await DataStore.save(
    ServiceRendered.copyOf(original, (updated) => {
      Object.assign(updated, {
        id: id,
        date: date,
        service: { id: serviceID },
        mileage: parseFloat(mileage),
        quantity: Number(quantity),
        mileageCost: Math.round(mileageCost + Number.EPSILON),
        serviceCost: serviceCost,
      });
    })
  );

  // await API.graphql({
  //   query: updateServiceRendered,
  //   variables: { input: activity },
  // });
};

export const DeleteActivityRendered = async (id: string) => {
  console.log("Deleting", id);
  await DataStore.delete(ServiceRendered, id);
  // const deleteInput: DeleteServiceRenderedInput = { id: id, _version: 1 }
  // const deleteResult = await API.graphql({ query: deleteServiceRendered, variables: { input: deleteInput } });
  // console.log(deleteResult);
};
