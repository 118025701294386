import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Avatar, CardContent, CardActions } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useNavigate } from "react-router-dom";

interface LinkCardProps {
  urlPath: string;
  title: string;
  icon: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  errors?: Array<React.ReactNode>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      [theme.breakpoints.up("sm")]: {
        width: "30%",
      },
      [theme.breakpoints.down('md')]: {
        width: "90%",
      },
      margin: "16px",
      display: "flex",
      "flex-direction": "column",
    },
    cardMain: {
      display: "flex",
      "flex-direction": "row",
    },
    cardContent: {
      "flex-grow": 2,
    },
    cardAction: {
      "flex-grow": 1,
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      color: "#fff",
      backgroundColor: blue[500],
    },
  })
);

const LinkCard: React.FC<LinkCardProps> = (props: LinkCardProps) => {
  const classes = useStyles();
  const history = useNavigate();

  const navigate = () => {
    history("/" + props.urlPath);
  };

  return (
    <Card className={classes.card} onClick={props.onClick ? props.onClick : navigate}>
      <CardContent className={classes.cardMain}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6">{props.title}</Typography>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Avatar className={classes.avatar}>{props.icon}</Avatar>
        </CardActions>
      </CardContent>
      {props.errors && props.errors.length > 0 && (
        <CardContent>
          {props.errors.map((e, i) => (
            <Typography key={i} color="textSecondary" gutterBottom>
              {e}
            </Typography>
          ))}
        </CardContent>
      )}
    </Card>
  );
};

export default LinkCard;
