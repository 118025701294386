import React from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import { Authenticator } from "@aws-amplify/ui-react";
Amplify.configure(awsExports);

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <SnackbarProvider maxSnack={3}>
//       <App />
//     </SnackbarProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
