import { DataStore } from "aws-amplify";
import { Settings } from "../models";

export const getSettings = async (): Promise<Settings> => {
  const settings = await DataStore.query(Settings);
  return settings.length > 0 ? settings[0] : { id: "new" };
  // const response = (await API.graphql({query: listSettingss})) as { data: ListSettingssQuery };
  // const settings = response.data.listSettingss?.items?.map(i => ({
  //     ...i
  // } as Settings)) || [];
  // return settings.length > 0 ? settings[0]: {id : "new"};
};

export const saveSettings = async (settings: Settings) => {
  // TODO: Change this so that when a user is created a settings object is created for them
  // then the settings will never be null.
  if (settings.id === "new") {
    console.log("Creating settings", settings);
    await DataStore.save(new Settings({ ...settings }));
    // const saveResult = (await API.graphql({query: createSettings, variables: {input: input}})) as { data: CreateSettingsMutation };
    // if(saveResult.data.createSettings){
    //     console.log("saved")
    //     return;
    // }
    // throw (new Error("Failed to save settings"));
  } else {
    console.log("Updating settings", settings);
    const original = await DataStore.query(Settings, settings.id);
    if (!original) {
      throw new Error("no existing settings found");
    }
    await DataStore.save(
      Settings.copyOf(original, (updated) => {
        Object.assign(updated, settings);
      })
    );

    // const updateResult = (await API.graphql({query: updateSettings, variables: {input: input}})) as { data: UpdateSettingsMutation };
    // console.log("Update result", updateResult);
    // if (updateResult.data.updateSettings) {
    //     return;
    // } else {
    //     throw (new Error("Failed to update settings"));
    // }
  }
};
