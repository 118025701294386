import React, { useState } from "react";
import { Card, Typography, CardContent, CardActions, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Service } from "../Data/Types";

interface ServiceCardProps {
  service: Service;
  saveService: (service: Service, isNew: boolean) => Promise<boolean>;
  deleteService: (service: Service) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "8px",
      display: "flex",
      "flex-direction": "row",
    },
    content: {
      display: "flex",
      "flex-direction": "row",
      "flex-grow": "3",
      "justify-content": "space-around",
    },
    mainContent: {
      margin: "8px",
    },
    actions: {
      "flex-grow": 1,
      "justify-content": "space-evenly",
    },
  })
);

const ServiceCard: React.FC<ServiceCardProps> = (props: ServiceCardProps) => {
  const classes = useStyles();
  const service = props.service;
  const [isEditable, setEditable] = useState(service.editable ? service.editable : false);
  const [name, setName] = useState(service.name);
  const [type, setType] = useState(service.type);
  const [isNew, setIsNew] = useState(service.name === "");

  const formatCurrency = (cost: number) => {
    return (cost / 100).toFixed(2);
  };
  const [cost, setCost] = useState(formatCurrency(service.cost));

  const formatDollarsToCents = (value: string) => {
    value = (value + "").replace(/[^\d.-]/g, "");
    if (value && value.includes(".")) {
      value = value.substring(0, value.indexOf(".") + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
  };

  const handleCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCost(event.target.value);
  };

  const handleSave = async () => {
    setEditable(false);
    console.log("Saving");
    setIsNew(
      !(await props.saveService(
        {
          id: service.id,
          name: name,
          cost: formatDollarsToCents(cost),
          type: type,
        },
        isNew
      ))
    );
    setCost(formatCurrency(formatDollarsToCents(cost)));
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {!isEditable ? (
          <div>
            <Typography variant="caption" display="block" gutterBottom>
              Description
            </Typography>
            <Typography id="description" className={classes.mainContent}>
              {name}
            </Typography>
          </div>
        ) : (
          <TextField
            id="description"
            label="Description"
            required
            className={classes.mainContent}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
          />
        )}
        {!isEditable ? (
          <div>
            <Typography variant="caption" display="block" gutterBottom>
              Type
            </Typography>
            <Typography id="type">{type}</Typography>
          </div>
        ) : (
          <TextField
            id="type"
            label="Type"
            required
            value={type}
            className={classes.mainContent}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setType(e.target.value)}
          />
        )}
        {!isEditable ? (
          <div>
            <Typography variant="caption" display="block" gutterBottom>
              Cost
            </Typography>
            <Typography id="cost">£{cost}</Typography>
          </div>
        ) : (
          <TextField
            id="cost"
            label="Cost"
            required
            value={cost}
            className={classes.mainContent}
            onChange={handleCostChange}
          />
        )}
      </CardContent>
      <CardActions className={classes.actions}>
        {!isEditable ? (
          <IconButton
            aria-label="Edit Service"
            color="primary"
            onClick={() => setEditable(true)}
            size="large">
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton aria-label="Save" color="primary" onClick={handleSave} size="large">
            <SaveIcon />
          </IconButton>
        )}
        <IconButton
          aria-label="Delete Service"
          color="secondary"
          onClick={() => props.deleteService(service)}
          size="large">
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default ServiceCard;
