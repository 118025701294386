import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import {
  Theme,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  TextField,
  Button,
  ListSubheader,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { loadClients } from "../Data/ClientAPI";
import { Client } from "../models/index";
import { loadActivities, saveActivityRendered } from "../Data/ActivitiesApi";
import DatePicker from "./DatePicker";
import { SettingsContext } from "../App";
import { Service } from "../Data/Types";

interface NewActivityPopupProps {
  isOpen: boolean;
  anchorEl: HTMLDivElement | HTMLButtonElement | null;
  onClose: () => void;
  clientID?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    header: {
      display: "flex",
      "flex-direction": "row",
      width: "100%",
      "justify-content": "space-between",
      "align-items": "center",
    },
    formContainer: {
      display: "flex",
      "justify-content": "center",
    },
    form: {
      display: "flex",
      "justify-content": "center",
      "flex-direction": "column",
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    formItem: {
      display: "flex",
      "flex-grow": 4,
      margin: "16px",
    },
    formSectionTravel: {
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-evenly",
      margin: "8px",
    },
  })
);

const NewActivityPopup: React.FC<NewActivityPopupProps> = (props: NewActivityPopupProps) => {
  const classes = useStyles();
  const [client, setClient] = useState(props.clientID ?? "");
  const [activity, setActivity] = useState("");
  const [allClients, setAllClients] = useState<Array<Client>>([]);
  const [allActivities, setAllActivities] = useState<Array<Service>>([]);
  const [mileage, setMileage] = useState<string>("0");
  const [mileageCost, setMileageCost] = useState(0);
  const [quantity, setQuantity] = useState("1");
  const [activityTypes, setActivityTypes] = useState<Array<string>>([]);
  const { isOpen, anchorEl, onClose } = props;
  const [dates, setDates] = useState<Array<Date>>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { settings } = React.useContext(SettingsContext);

  const clearClient = props.clientID ? false : true;

  useEffect(() => {
    fetchClients();
    fetchActivities();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const matchingClient = allClients.find((c) => c.id === client);
    if (matchingClient?.defaultMileage) {
      setMileage(matchingClient.defaultMileage.toString());
    }
  }, [client, allClients]);

  useEffect(() => {
    setMileageCost(settings?.mileageCost ?? 0);
  }, [settings]);

  const fetchClients = async () => {
    try {
      const clientData = await loadClients();
      setAllClients(clientData);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to load clients", { variant: "error" });
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await loadActivities();
      const sortedActivities = response.sort((a, b) => (a.name < b.name ? -1 : 1));
      setAllActivities(sortedActivities);
      const uniqueTypes = Array.from(new Set(response.map((a) => a.type ?? ""))).sort((a, b) => (a < b ? -1 : 1));
      setActivityTypes(uniqueTypes);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to load available services", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setMileageCost(Number(mileage) * (settings?.mileageCost ?? 0));
  }, [mileage, settings]);

  const setDate = (date: Date | Array<Date>) => {
    if (!Array.isArray(date)) {
      setDates([date]);
    } else {
      setDates(date);
    }
  };

  const clearState = () => {
    //TODO change to useReducer and move state into one object
    if (clearClient) {
      setClient("");
      setMileage("0");
      setMileageCost(0);
    }
    setActivity("");
    setQuantity("1");
    setDates([]);
  };

  const handleSubmit = async () => {
    enqueueSnackbar("Creating activities");
    Promise.all(
      dates.map(async (date) => {
        await saveActivityRendered(date, client, activity, mileage, quantity, mileageCost);
      })
    )
      .then((res) => {
        enqueueSnackbar(`Created ${dates.length} ${dates.length > 1 ? "activities" : "activity"}`);
      })
      .catch((e) => {
        enqueueSnackbar("Failed to create activity", {
          variant: "error",
        });
      });
    clearState();
    props.onClose();
  };

  return (
    <Popover
      className={classes.root}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{ style: { width: "100%", height: "100%" } }}
    >
      <div className={classes.header}>
        <Typography variant="h5">Add New Activity</Typography>
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.formContainer}>
        <form className={classes.form}>
          <FormControl className={classes.formItem}>
            <InputLabel id="client-select-label">Client</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={client}
              onChange={(event: SelectChangeEvent) => setClient(event?.target.value as string)}
            >
              {allClients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.reference ?? client.name + " " + client.surname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formItem}>
            <InputLabel id="activity-select-label">Activity</InputLabel>
            <Select
              labelId="activity-select-label"
              id="activity-select"
              value={activity}
              onChange={(event: SelectChangeEvent) => setActivity(event?.target.value as string)}
            >
              {activityTypes.map((type) => {
                const res: Array<React.ReactElement> = [];
                res.push(<ListSubheader>{type !== "" ? type : "Other"}</ListSubheader>);
                allActivities
                  .filter((a) => {
                    return a.type === type || (type === "" && !a.type);
                  })
                  .forEach((a) => {
                    res.push(
                      <MenuItem key={a.id} value={a.id}>
                        {a.name}
                      </MenuItem>
                    );
                  });
                return res;
              })}
            </Select>
          </FormControl>
          <div className={classes.formSectionTravel}>
            <TextField id="mileage" label="Mileage" value={mileage} onChange={(e) => setMileage(e.target.value)} />
            <TextField id="quantity" label="Quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
          </div>
          <DatePicker setDate={setDate} />
          <Button
            aria-label="add-activity"
            variant="contained"
            color="primary"
            disabled={!activity || !client || !quantity}
            onClick={handleSubmit}
          >
            Add
          </Button>
        </form>
      </div>
    </Popover>
  );
};

export default NewActivityPopup;
