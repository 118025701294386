import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { SettingsContext } from "../App";

interface EmailTextConfirmProps {
  confirmFunction: (message: string, subject: string) => void;
  rejectFunction: () => void;
  open: boolean;
}

const EmailTextConfirm: React.FC<EmailTextConfirmProps> = (props: EmailTextConfirmProps) => {
  const { open, rejectFunction, confirmFunction } = props;
  const { settings } = React.useContext(SettingsContext);
  const [messageText, setMessageText] = useState(settings?.emailSettings?.defaultText ?? "");
  const [subject, setSubject] = useState(settings?.emailSettings?.subject ?? "");

  useEffect(() => {
    setMessageText(settings?.emailSettings?.defaultText ?? "");
    setSubject(settings?.emailSettings?.subject ?? "");
  }, [settings]);

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle id="confirmation-dialog">This text will be sent with your invoice</DialogTitle>
      <DialogContent>
        <TextField
          id="email-subject"
          label="E-Mail Subject"
          value={subject}
          style={{ width: "80%" }}
          onChange={(e) => setSubject(e.target.value)}
        />
        <div>
          <TextareaAutosize
            id="email-text"
            value={messageText}
            placeholder="The text you would like to appear in your E-Mails"
            minRows={5}
            style={{ width: "80%" }}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <Typography variant="caption" display="block" gutterBottom>
            Use these tags to insert data into your email or subject:
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            [client_name], [client_surname], [invoice_name], [invoice_total], [invoice_label]
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={rejectFunction}>Cancel</Button>
        <Button onClick={() => confirmFunction(messageText, subject)}>Send</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailTextConfirm;
