import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import Home from "./pages/Home";
import MenuBar from "./Components/MenuBar";
import "./App.css";
import Services from "./pages/Services";
import ClientsPage from "./pages/Clients";
import Settings from "./pages/Settings";
import ClientDetails from "./Components/ClientDetails";
import { Settings as SettingsModel } from "./models/index";
import { getSettings } from "./Data/SettingsAPI";
import Invoices from "./pages/Invoices";
import Vets from "./pages/Vets";
import { DataStore, Hub } from "aws-amplify";
import { CircularProgress } from "@mui/material";
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { indigo } from "@mui/material/colors";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const SettingsContext = React.createContext({
  settings: {} as SettingsModel | undefined,
  updateSettings: (s: SettingsModel) => {},
});

const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
      light: "#6573c3",
      dark: "#2c387e",
    },
    secondary: {
      main: "#f50057",
      light: "#f73378",
      dark: "#ab003c",
    },
  },
});

function App() {
  const [settings, setSettings] = useState<SettingsModel>();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [loading, setLoading] = useState(true);

  Hub.listen("auth", async (data) => {
    if (data.payload.event === "signOut") {
      await DataStore.clear();
    }
  });

  Hub.listen("datastore", async (data) => {
    if (data.payload.event === "ready") {
      if (settings === undefined || settings.id === "new") {
        updateSettings(await getSettings());
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    const getInitialSettings = async () => {
      await DataStore.start();
      updateSettings(await getSettings());
      //setSettings(await getSettings());
    };
    if (authStatus === "authenticated") {
      getInitialSettings();
    }
  }, [authStatus]);

  const updateSettings = (s: SettingsModel) => {
    //console.log("new settings in context", s);
    setSettings(s);
  };

  const formFields = {
    signUp: {
      email: {
        order: 1,
      },
      password: {
        order: 2,
      },
      confirm_password: {
        order: 3,
      },
    },
  };

  return (
    <div className="App">
      {authStatus === "authenticated" ? (
        loading ? (
          <CircularProgress />
        ) : (
          <SettingsContext.Provider value={{ settings: settings, updateSettings: updateSettings }}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <BrowserRouter>
                    <MenuBar />
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/services" element={<Services />} />
                      <Route path="/clients" element={<ClientsPage />} />
                      <Route path="/clients/:id" element={<ClientDetails />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/invoices" element={<Invoices />} />
                      <Route path="/vets" element={<Vets />} />
                    </Routes>
                  </BrowserRouter>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </SettingsContext.Provider>
        )
      ) : (
        <Authenticator formFields={formFields}>
          {({ signOut }) => <button onClick={signOut}>Sign out</button>}
        </Authenticator>
      )}
    </div>
  );
}

export default App;
//export default App;
