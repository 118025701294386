import React, { useState, useEffect } from "react";
import { Theme, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import LoadingView from "../Components/LoadingView";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import { LoadVets, DeleteVet, SaveVet, UpdateVet } from "../Data/VetApi";
import { DisplayableVet as Vet } from "../Data/Types";
import VetCard from "../Components/VetCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "8px",
    },
    addButton: {
      position: "fixed",
      [theme.breakpoints.down('md')]: {
        "z-index": 1100,
        top: "2.25em",
        left: "50%",
        transform: "translateX(-50%)",
      },
      [theme.breakpoints.up("sm")]: {
        bottom: "1em",
        right: "1em",
      },
    },
    noData: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      height: "100vh",
    },
    filter: {
      "margin-left": "auto",
      "flex-grow": 0.3,
    },
  })
);

const Vets: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [vets, setVets] = useState<Array<Vet>>([]);
  const [allVets, setAllVets] = useState<Array<Vet>>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchVets();
    // eslint-disable-next-line
  }, []);

  const fetchVets = async () => {
    try {
      const response = await LoadVets();
      setVets(response);
      setAllVets(response);
    } catch (e) {
      enqueueSnackbar("Failed to load vets", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const addVet = () => {
    const newVet: Vet = {
      id: uuidv4(),
      name: "",
      phoneNumber: "",
      editable: true,
    };
    const v = Object.assign([], vets);
    v.push(newVet);
    setVets(v);
    console.log("v", v);
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      //eslint-disable-next-line
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const saveVet = async (vet: Vet, isNew: boolean): Promise<boolean> => {
    if (isNew) {
      try {
        const newVet = await SaveVet(vet);
        const v = [...vets];
        const index = v.findIndex((i) => i.id === vet.id);
        v[index] = { ...newVet, editable: false };
        setVets(v);
        setAllVets(v);
        return true;
      } catch (e) {
        enqueueSnackbar("Failed to save vet", { variant: "error" });
        console.log("Save error", e);
      }
    } else {
      try {
        await UpdateVet(vet);
        return true;
      } catch (e) {
        enqueueSnackbar("Failed to save vet", { variant: "error" });
        console.log("Save error", e);
      }
    }
    return false;
  };

  const deleteVet = async (vet: Vet) => {
    await DeleteVet(vet.id);
    enqueueSnackbar("Vet deleted");
    setVets(vets.filter((v) => v.id !== vet.id));
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = allVets.filter((v) => v.name.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()));
    setVets(filtered);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingView />
      ) : (
        <Paper className={classes.root}>
          {vets && (
            <TextField
              className={classes.filter}
              onChange={handleFilterChange}
              id="filter"
              label="Vet Name"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          {vets.map((v: Vet) => (
            <VetCard key={v.id} vet={v} saveVet={saveVet} deleteVet={deleteVet} />
          ))}
          {!vets ||
            (vets.length === 0 && (
              <div className={classes.noData}>
                <Typography variant="h6">You don't have any vets yet</Typography>
                <Typography variant="subtitle1">Tap + to create one</Typography>
              </div>
            ))}
          <Fab color="secondary" aria-label="add" className={classes.addButton} onClick={addVet}>
            <AddIcon />
          </Fab>
        </Paper>
      )}
    </div>
  );
};

export default Vets;
