import React, { useState, useEffect } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Client, Invoice, InvoiceStatus } from "../models";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Collapse,
  Button,
  Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import ActivityTable from "./ActivityTable";
import { Storage } from "aws-amplify";

interface InvoiceTableProps {
  clients: Array<Client>;
  clientInvoices: Array<Invoice>;
  update: () => void;
  setSelectedInvoices: React.Dispatch<React.SetStateAction<Invoice[]>>;
  selectedInvoices: Array<Invoice>;
  disableExpand?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "flex-wrap": "wrap",
      "justify-content": "center",
    },
    activityHeader: {
      display: "flex",
    },
  })
);

const InvoiceTable: React.FC<InvoiceTableProps> = (props: InvoiceTableProps) => {
  const classes = useStyles();
  const [clients, setClients] = useState(props.clients);
  const [clientInvoices, setClientInvoices] = useState(props.clientInvoices);
  const { selectedInvoices, setSelectedInvoices, disableExpand } = props;

  useEffect(() => {
    setClients(props.clients);
    setClientInvoices(props.clientInvoices);
  }, [props]);

  useEffect(() => {});

  const subtotal = () => {
    let total = 0;
    props.clientInvoices
      .filter((i) => i.status !== InvoiceStatus.VOIDED && i.status !== InvoiceStatus.CANCELLED)
      .forEach((i) => {
        total = total + (i?.subtotal ?? 0);
      });
    return total;
  };

  const selectAll = () => {
    // const invoiceIds: Array<string> = []
    //     clients.forEach(c => c.invoices?.forEach(i => {if (i) {invoiceIds.push(i.id)}}));
    // if(selectedClients.length === invoiceIds.length){
    //     setSelectedClients([]);
    // } else {
    //     setSelectedClients(invoiceIds);
    // }
    if (selectedInvoices.length === clientInvoices.length) {
      setSelectedInvoices([]);
    } else {
      const allInvoices: Array<Invoice> = [];
      clientInvoices.forEach((i) => {
        allInvoices.push(i);
      });
      setSelectedInvoices(allInvoices);
    }
  };

  const select = (invoice: Invoice) => {
    if (selectedInvoices.filter((i) => i.id === invoice.id).length > 0) {
      setSelectedInvoices(selectedInvoices.filter((i) => i.id !== invoice.id));
    } else {
      setSelectedInvoices(selectedInvoices.concat(invoice));
    }
    // if(selectedClients.includes(invoiceId)){
    //     setSelectedClients(selectedClients.filter(c => c !== invoiceId));
    // } else {
    //     setSelectedClients(selectedClients.concat(invoiceId));
    // }
  };

  //TODO find out when to use react fragments and when not, wrapper here should maybe be a div?
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {!disableExpand && (
              <TableCell>
                <Checkbox
                  disabled={clients.length === 0}
                  checked={clientInvoices.length > 0 && selectedInvoices.length === clientInvoices.length}
                  onClick={selectAll}
                />
              </TableCell>
            )}
            <TableCell>Name / Reference</TableCell>
            <TableCell style={{ width: "8em" }}>First Activity Date</TableCell>
            <TableCell style={{ width: "8em" }}>Last Activity Date</TableCell>
            <TableCell>Label</TableCell>
            <TableCell align="center">Subtotal</TableCell>
            <TableCell>Invoice</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientInvoices.map((invoice: Invoice) => {
            const client = clients.filter((c) => c.id === invoice.clientID);
            if (!client || client.length === 0) {
              return null;
            }
            return (
              <CollapsableRow
                client={client[0]}
                key={invoice.id}
                update={props.update}
                isSelected={selectedInvoices.filter((i) => i.id === invoice.id).length > 0}
                select={select}
                invoice={invoice}
                disableExpand={disableExpand}
              />
            );
          })}
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell>Subtotal</TableCell>
            <TableCell align="center">{formatCurrency(subtotal())}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function formatCurrency(amount: number) {
  return "£" + (amount / 100).toFixed(2);
}

const CollapsableRow: React.FC<{
  client: Client;
  update: () => void;
  isSelected: boolean;
  select: (invoice: Invoice) => void;
  invoice: Invoice;
  disableExpand?: boolean;
}> = (props: {
  client: Client;
  update: () => void;
  isSelected: boolean;
  select: (invoice: Invoice) => void;
  invoice: Invoice;
  disableExpand?: boolean;
}) => {
  const classes = useStyles();
  const { client, invoice, disableExpand } = props;
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [signedUrl, setSignedUrl] = useState<string>("");

  useEffect(() => {
    const createSignedUrl = async () => {
      if (invoice.filePath) {
        const path = invoice.filePath.replace(/^.*[\\/]/, "");
        const download = await Storage.get(path, {
          level: "private",
        });
        if (typeof download !== "string") {
          return;
        }
        setSignedUrl(download);
      }
    };
    createSignedUrl();
  }, [invoice]);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={disableExpand || (invoice.status && invoice.status === InvoiceStatus.VOIDED ? true : false)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {!disableExpand && (
          <TableCell>
            <Checkbox checked={props.isSelected} onClick={() => props.select(invoice)} />
          </TableCell>
        )}
        <TableCell>
          <Button onClick={() => history(`/clients/${client.id}`)}>
            {client.name} {client.surname} {client.reference ? " / " + client.reference : ""}
          </Button>
        </TableCell>
        <TableCell>{invoice.startDate}</TableCell>
        <TableCell>{invoice.endDate}</TableCell>
        <TableCell>{invoice.label !== "" && invoice.label !== null ? invoice.label : "-"}</TableCell>
        <TableCell align="center">{invoice.subtotal ? formatCurrency(invoice.subtotal) : 0}</TableCell>
        <TableCell>
          {invoice.filePath && invoice.invoiceName ? (
            <a href={signedUrl} target="_blank" rel="noopener noreferrer">
              {invoice.invoiceName}
            </a>
          ) : (
            "N/A"
          )}
        </TableCell>
        <TableCell> {invoice.status ? invoice.status.toUpperCase() : "-"} </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.activityHeader} margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Activities
              </Typography>
            </Box>
            <ActivityTable data={invoice.servicesRendered} clientId={client?.id} updateSource={props.update} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default InvoiceTable;
