// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const InvoiceStatus = {
  "CREATED": "created",
  "SENT": "sent",
  "PAID": "paid",
  "CANCELLED": "cancelled",
  "VOIDING": "voiding",
  "VOIDED": "voided",
  "CREATING": "creating",
  "SENDING": "sending"
};

const { ServiceRendered, Client, Pet, Vet, Service, Invoice, Settings, BankDetails, CompanyDetails, EmailSettings, EmailTemplate } = initSchema(schema);

export {
  ServiceRendered,
  Client,
  Pet,
  Vet,
  Service,
  Invoice,
  Settings,
  InvoiceStatus,
  BankDetails,
  CompanyDetails,
  EmailSettings,
  EmailTemplate
};