import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Settings } from "@mui/icons-material";

interface MenuBarProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      "flex-grow": 1,
    },
    appBarTitle: {
      "flex-grow": 1,
    },
    menuAppBar: {
      "flex-grow": 0,
    },
  })
);

export default function MenuBar(props: MenuBarProps) {
  const classes = useStyles();
  const { signOut } = useAuthenticator((context) => [context.user]);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRedirect = (link: string) => {
    handleClose();
    navigate("/" + link);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pageTitle = () => {
    return "";
  };

  return (
    <div className={classes.appBar}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className="app_bar_menu"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            className={classes.menuAppBar}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleRedirect("")}>Home</MenuItem>
            <MenuItem onClick={() => handleRedirect("services")}>Services</MenuItem>
            <MenuItem onClick={() => handleRedirect("clients")}>Clients</MenuItem>
            <MenuItem onClick={() => handleRedirect("invoices")}>Invoices</MenuItem>
            <MenuItem onClick={() => handleRedirect("settings")}>Settings</MenuItem>
          </Menu>
          <IconButton
            edge="start"
            className="app_bar_menu"
            color="inherit"
            aria-label="home"
            onClick={() => handleRedirect("")}
            size="large"
          >
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" className={classes.appBarTitle}>
            {pageTitle()}
          </Typography>
          <Button variant="contained" disableElevation onClick={signOut}>
            Sign Out
          </Button>
          <IconButton color="inherit" aria-label="settings" onClick={() => handleRedirect("settings")} size="large">
            <Settings />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}
