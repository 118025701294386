import React, { useState, useEffect } from "react";
import { DatePicker as DP } from "@mui/x-date-pickers/DatePicker";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";

interface DatePickerProps {
  setDate: (date: Date | Array<Date>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: "16px",
      [theme.breakpoints.down("md")]: {
        "flex-direction": "column",
      },
    },
    radios: {
      display: "flex",
      "flex-direction": "row",
      margin: theme.spacing(2),
    },
    dayCheckBoxes: {
      "margin-top": "16px",
    },
    dateContainer: {
      margin: theme.spacing(1),
    },
  })
);

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const DatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [recurring, setRecurring] = useState(false);
  const [checkedState, setCheckedState] = useState<Array<string>>([]);
  const [radioValue, setRadioValue] = useState("every-day");

  const { setDate } = props;

  const selectedDates = (radValue: string | null) => {
    let dates: Array<Date> = [];
    if (startDate === null || endDate === null) {
      return;
    }
    if (!recurring) {
      setDate(startDate);
      return;
    }

    const theDate = new Date(startDate);
    switch (radValue) {
      case "every-day":
        while (theDate <= endDate) {
          dates = [...dates, new Date(theDate)];
          theDate.setDate(theDate.getDate() + 1);
        }
        break;
      case "week-days":
        while (theDate <= endDate) {
          if (theDate.getDay() !== 6 && theDate.getDay() !== 0) {
            dates = [...dates, new Date(theDate)];
          }
          theDate.setDate(theDate.getDate() + 1);
        }
        break;
      case "weekends":
        while (theDate <= endDate) {
          if (theDate.getDay() === 6 || theDate.getDay() === 0) {
            dates = [...dates, new Date(theDate)];
          }
          theDate.setDate(theDate.getDate() + 1);
        }
        break;
      case "specific":
        const dayNumberArray: Array<number> = [];
        checkedState.forEach((i) => {
          dayNumberArray.push(days.indexOf(i));
        });
        while (theDate <= endDate) {
          if (dayNumberArray.includes(theDate.getDay())) {
            dates = [...dates, new Date(theDate)];
          }
          theDate.setDate(theDate.getDate() + 1);
        }
        break;
    }
    setDate(dates);
  };

  useEffect(() => {
    selectedDates(radioValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, recurring, checkedState, radioValue]);

  useEffect(() => {
    if (endDate !== null && startDate !== null && endDate < startDate) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]);

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    if (checkedState.includes(name)) {
      const arr = [...checkedState];
      const index = arr.indexOf(name);
      if (index > -1) {
        arr.splice(index, 1);
        setCheckedState(arr);
      }
    } else {
      setCheckedState(checkedState.concat(event.target.name));
    }
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            checked={recurring}
            onChange={(e) => setRecurring(e.target.checked)}
            name="recurring"
            color="primary"
          />
        }
        label="Recurring"
      />
      <div className={classes.dateContainer}>
        <DP
          slotProps={{
            textField: {
              required: true,
              id: "start-date",
              "aria-label": "change date",
            },
          }}
          format="dd/MM/yyyy"
          label={recurring ? "Start Date" : "Date"}
          onChange={setStartDate}
          value={startDate}
          closeOnSelect
        />
        {recurring && (
          <DP
            slotProps={{
              textField: {
                required: true,
                id: "end-date",
                "aria-label": "change date",
              },
            }}
            format="dd/MM/yyyy"
            label="End Date"
            onChange={setEndDate}
            value={endDate}
            closeOnSelect
          />
        )}
      </div>
      {recurring && (
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              className={classes.radios}
              value={radioValue}
              onChange={(e) => setRadioValue((e.target as HTMLInputElement).value)}
            >
              <FormControlLabel value="every-day" control={<Radio />} label="Every Day" />
              <FormControlLabel value="week-days" control={<Radio />} label="Week Days" />
              <FormControlLabel value="weekends" control={<Radio />} label="Weekends" />
              <FormControlLabel value="specific" control={<Radio />} label="Specific Days" />
            </RadioGroup>
            {radioValue === "specific" && (
              <div>
                <Divider variant="middle" />
                <FormGroup className={classes.dayCheckBoxes}>
                  <FormLabel component="legend">Specific Days</FormLabel>
                  {days.map((d) => (
                    <DailyCheckbox name={d} key={d} onChange={handleChecked} checkedState={checkedState} />
                  ))}
                </FormGroup>
              </div>
            )}
          </FormControl>
        </div>
      )}
    </div>
  );
};

interface DailyCheckboxProps {
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkedState: Array<string>;
}

const DailyCheckbox = (props: DailyCheckboxProps) => {
  const { name, onChange, checkedState } = props;
  return (
    <FormControlLabel
      control={<Checkbox checked={checkedState.includes(name)} onChange={onChange} name={name} />}
      label={name}
    />
  );
};

export default DatePicker;
