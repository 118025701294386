import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";

interface ConfirmationAlertProps {
  title: string;
  message: string;
  confirmFunction: () => void;
  rejectFunction: () => void;
  open: boolean;
}

const ConfirmationAlert: React.FC<ConfirmationAlertProps> = (props: ConfirmationAlertProps) => {
  const { open, message, title, rejectFunction, confirmFunction } = props;

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={rejectFunction}>No</Button>
        <Button onClick={confirmFunction}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationAlert;
