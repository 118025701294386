import { DataStore } from "aws-amplify"
import { Vet } from "../models"
import { DisplayableVet } from "./Types";

export const LoadVets = async (): Promise<Array<Vet>> => {
    return await DataStore.query(Vet);
}

export const DeleteVet = async(vetId: string) => {
    await DataStore.delete(Vet, vetId);
}

export const SaveVet = async(vet: DisplayableVet): Promise<DisplayableVet> => {
    return await DataStore.save(new Vet({...vet}));
}

export const UpdateVet = async(vet: DisplayableVet) => {
    const original = await DataStore.query(Vet, vet.id);
    if (!original){
        throw new Error("Failed to find vet to update");
    }

    await DataStore.save(Vet.copyOf(original, updated => {
        Object.assign(updated, vet);
    }));
}