import {
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { Address } from "../Data/Types";
import { Client } from "../models";
import { LookupAddress } from "../api/LocationAPI";

interface ClientAddressPopupProps {
  open: boolean;
  handleClose: (address: Address | undefined) => void;
  client: Client;
}

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridRoot: {
    padding: "1em",
  },
}));

const ClientAddressPopup: React.FC<ClientAddressPopupProps> = (props: ClientAddressPopupProps) => {
  const classes = useStyles();
  const [address, setAddress] = useState<Address>();
  const [lookupAddress, setLookupAddress] = useState("");
  const [lookupPostcode, setLookupPostcode] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const { open, handleClose, client } = props;

  useEffect(() => {
    setAddress({
      address: client.address,
      addressLine2: client.addressLine2,
      town: client.town,
      county: client.county,
      postCode: client.postCode,
    });
  }, [client]);

  const search = () => {
    setSearching(true);
    LookupAddress(lookupPostcode, lookupAddress).then((res: Address | null) => {
      setSearching(false);
      if (res !== null) {
        setAddress(res);
      } else {
        setNotFound(true);
      }
    });
  };

  return (
    <Popover
      open={open}
      anchorReference={"none"}
      onClose={() => handleClose(undefined)}
      classes={{
        root: classes.popoverRoot,
      }}
    >
      <Grid container direction="column" spacing={1} className={classes.gridRoot}>
        <Grid item>
          <Typography variant="h5">Address Details</Typography>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="subtitle1">Lookup Address</Typography>
          </Grid>
          <Grid item container>
            <Grid item xs={8}>
              <TextField
                autoComplete="off"
                label="First line of Address"
                value={lookupAddress}
                onChange={(e) => setLookupAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Post Code"
                autoComplete="off"
                value={lookupPostcode}
                onChange={(e) => setLookupPostcode(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={search} size="large">
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>{isSearching && <LinearProgress />}</Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container direction="column">
          {notFound ? (
            <Typography variant="subtitle1" style={{ color: "red" }}>
              Address not found please enter manually
            </Typography>
          ) : (
            <Typography variant="subtitle1">or enter manually</Typography>
          )}
          <TextField
            label="Address"
            value={address?.address}
            onChange={(e) => setAddress({ ...address, address: e.target.value })}
          />
          <TextField
            label="Address Line 2"
            value={address?.addressLine2}
            onChange={(e) => setAddress({ ...address, addressLine2: e.target.value })}
          />
          <TextField
            label="Town"
            value={address?.town}
            onChange={(e) => setAddress({ ...address, town: e.target.value })}
          />
          <TextField
            label="County"
            value={address?.county}
            onChange={(e) => setAddress({ ...address, county: e.target.value })}
          />
          <TextField
            label="Post Code"
            value={address?.postCode}
            onChange={(e) => setAddress({ ...address, postCode: e.target.value })}
          />
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Button color="primary" onClick={() => handleClose(undefined)}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleClose(address)}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default ClientAddressPopup;
