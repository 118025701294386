import React, { useEffect, useState, useRef } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { loadClients, loadClientsForInvoicing } from "../Data/ClientAPI";
import { Client, Invoice, InvoiceStatus } from "../models";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InvoiceTable from "../Components/InvoiceTable";
import InvoiceActions from "../Components/InvoiceActions";
import { CreateInvoices, DeleteInvoice, UpdateInvoice, CancelInvoice, SendInvoices } from "../Data/InvoiceApi";
import { useSnackbar } from "notistack";
import { DataStore } from "aws-amplify";
import EmailTextConfirm from "../Components/EmailTextConfirm";
import InvoiceLabelDialog from "../Components/InvoiceLabelDialog";

interface InvoicesProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "flex-wrap": "wrap",
      "justify-content": "center",
      "flex-direction": "column",
    },
    dateSelect: {
      margin: "2em",
    },
  })
);

const Invoices: React.FC<InvoicesProps> = (props: InvoicesProps) => {
  const classes = useStyles();
  const [clientInvoices, setClientInvoices] = useState<Invoice[]>([]);
  const [clients, setClients] = useState<Array<Client>>([]);
  const today = new Date();
  const [startDate, setStartDate] = useState<Date | null>(new Date(today.getFullYear(), today.getMonth(), 1));
  const [endDate, setEndDate] = useState<Date | null>(new Date(today.getFullYear(), today.getMonth() + 1, 0));
  const prevStartDate = useRef<Date | null>();
  const [emailConfirmOpen, setEmailConfirmOpen] = useState(false);
  const [invoiceLabelOpen, setInvoiceLabelOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedInvoices, setSelectedInvoices] = useState<Array<Invoice>>([]);

  const fetchClientData = React.useCallback(async () => {
    const clientData = await loadClientsForInvoicing(startDate, endDate);
    setClients(await loadClients());
    setClientInvoices(clientData);
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate && startDate > endDate && prevStartDate.current !== startDate) {
      setEndDate(startDate);
    }
    if (startDate && endDate && startDate > endDate && prevStartDate.current === startDate) {
      setStartDate(endDate);
    }
    prevStartDate.current = startDate;

    fetchClientData();
  }, [startDate, endDate, fetchClientData]);

  const update = async () => {
    const clientData = await loadClientsForInvoicing(startDate, endDate);
    setClientInvoices(clientData);
  };

  useEffect(() => {
    const subscription = DataStore.observe(Invoice).subscribe((msg) => {
      //console.log("subscription", msg);
      if (msg.opType === "INSERT" || msg.opType === "UPDATE") {
        let data = [...clientInvoices];
        //console.log("data", data);
        if (msg.opType === "INSERT") {
          data = data.map((i) => {
            if (
              i.clientID === msg.element.clientID &&
              i.startDate === msg.element.startDate &&
              i.status === InvoiceStatus.CREATING
            ) {
              return msg.element;
            }
            return i;
          });
        } else {
          data = data.map((i) => (i.id === msg.element.id ? (i = msg.element) : i));
        }
        //console.log("new data", data);

        setClientInvoices(data);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  });

  const deleteInvoices = async () => {
    const selectedInvoiceIds = selectedInvoices.map((i) => i.id);
    let changedInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) > -1);

    changedInvoices = changedInvoices.map((c) => {
      const invoice: Invoice = { ...c, status: undefined, filePath: undefined, invoiceName: "" };
      return invoice;
    });

    const remainingInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) === -1);
    // console.log("remainingInvoices", remainingInvoices);
    // console.log("changedInvoices", changedInvoices);
    setClientInvoices([...remainingInvoices, ...changedInvoices]);
    selectedInvoices.forEach(async (i) => {
      await DeleteInvoice(i.id);
    });
    setSelectedInvoices([]);
  };

  const sendInvoices = async (message: string, subject: string) => {
    const selectedInvoiceIds = selectedInvoices.map((i) => i.id);
    let changedInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) > -1);
    console.log("Send invoices for invoice Ids:", selectedInvoiceIds);

    // Update the status on the selected invoices
    changedInvoices = changedInvoices.map((c) => {
      const invoice: Invoice = { ...c, status: InvoiceStatus.SENDING };
      //UpdateInvoice(invoice);
      return invoice;
    });

    // Set the invoices in state to show the updated ones
    const remainingInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) === -1);
    setClientInvoices([...remainingInvoices, ...changedInvoices]);

    setEmailConfirmOpen(false);

    SendInvoices(selectedInvoiceIds, message, subject).catch((err) => {
      enqueueSnackbar("Failed to send selected invoices", {
        variant: "error",
      });
      changedInvoices = changedInvoices.map((c) => {
        const invoice: Invoice = { ...c, status: InvoiceStatus.CREATED };
        //UpdateInvoice(invoice);
        return invoice;
      });
      const remainingInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) === -1);
      setClientInvoices([...remainingInvoices, ...changedInvoices]);
    });

    setSelectedInvoices([]);
  };

  const createInvoices = async (label?: string) => {
    setInvoiceLabelOpen(false);
    const selectedInvoiceIds = selectedInvoices.map((i) => i.id);
    let changedInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) > -1);
    const changedInvoicesClientIds = Array.from(new Set(changedInvoices.map((i) => i.clientID)));

    console.log("Create invoices for client Ids:", changedInvoicesClientIds);

    changedInvoices = changedInvoices.map((c) => {
      const invoice: Invoice = { ...c, status: InvoiceStatus.CREATING, label: label };
      return invoice;
    });

    const remainingInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) === -1);
    setClientInvoices([...remainingInvoices, ...changedInvoices]);
    CreateInvoices(startDate ?? new Date(), endDate ?? new Date(), changedInvoicesClientIds, label ?? "")
      .then((res) => {})
      .catch((err) => {
        enqueueSnackbar("Failed to create invoices", {
          variant: "error",
        });
        changedInvoices = changedInvoices.map((c) => {
          const invoice: Invoice = { ...c, status: undefined };
          return invoice;
        });
        const remainingInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) === -1);
        setClientInvoices([...remainingInvoices, ...changedInvoices]);
      });
    setSelectedInvoices([]);
  };

  const markInvoicesAs = (status: InvoiceStatus) => {
    const selectedInvoiceIds = selectedInvoices.map((i) => i.id);
    let changedInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) > -1);

    changedInvoices = changedInvoices.map((c) => {
      const invoice: Invoice = { ...c, status: status };
      return invoice;
    });

    const remainingInvoices = clientInvoices.filter((i) => selectedInvoiceIds.indexOf(i.id) === -1);
    setClientInvoices([...remainingInvoices, ...changedInvoices]);
    changedInvoices.forEach((i) => {
      if (status === InvoiceStatus.VOIDING) {
        CancelInvoice(i).then((res) => {
          console.log("Voided invoice");
          update();
        });
      } else {
        UpdateInvoice(i)
          .then((res) => {
            console.log("Updated invoice", i);
          })
          .catch((err) => {
            console.log("Failed to update invoice", err);
          });
      }
    });
    setSelectedInvoices([]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.dateSelect}>
        <DatePicker format="dd/MM/yyyy" label="Start Date" closeOnSelect value={startDate} onChange={setStartDate} />
        <DatePicker format="dd/MM/yyyy" label="End Date" closeOnSelect value={endDate} onChange={setEndDate} />
      </div>
      <InvoiceActions
        createInvoices={() => setInvoiceLabelOpen(true)}
        sendInvoices={() => setEmailConfirmOpen(true)}
        clients={clients}
        selectedInvoices={selectedInvoices}
        deleteInvoices={deleteInvoices}
        markInvoicesAs={markInvoicesAs}
      />
      {clientInvoices && clientInvoices.length > 0 && (
        <InvoiceTable
          clients={clients}
          clientInvoices={clientInvoices}
          update={update}
          setSelectedInvoices={setSelectedInvoices}
          selectedInvoices={selectedInvoices}
        />
      )}
      <EmailTextConfirm
        open={emailConfirmOpen}
        confirmFunction={sendInvoices}
        rejectFunction={() => setEmailConfirmOpen(false)}
      />
      <InvoiceLabelDialog
        open={invoiceLabelOpen}
        confirmFunction={createInvoices}
        rejectFunction={createInvoices}
        multiple={selectedInvoices.length > 1}
      />
    </div>
  );
};

export default Invoices;
