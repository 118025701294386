import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import LoadingView from "../Components/LoadingView";
import ServiceCard from "../Components/ServiceCard";
import { Service } from "../Data/Types";
import { loadActivities, SaveActivity, UpdateActivity, DeleteActivity } from "../Data/ActivitiesApi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "8px",
    },
    addButton: {
      position: "fixed",
      [theme.breakpoints.down('md')]: {
        "z-index": 1100,
        top: "2.25em",
        left: "50%",
        transform: "translateX(-50%)",
      },
      [theme.breakpoints.up("sm")]: {
        bottom: "1em",
        right: "1em",
      },
    },
    noData: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      height: "100vh",
    },
  })
);

const Services: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState<Array<Service>>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line
  }, []);

  const fetchServices = async () => {
    try {
      const response = await loadActivities();
      const sortedActivities = response.sort((a, b) => (a.name < b.name ? -1 : 1));
      setServices(sortedActivities);
    } catch (e) {
      enqueueSnackbar("Failed to load services", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const addService = () => {
    const newService: Service = {
      id: uuidv4(),
      name: "",
      cost: 0,
      editable: true,
    };
    const s = Object.assign([], services);
    s.push(newService);
    setServices(s);
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      //eslint-disable-next-line
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const saveService = async (service: Service, isNew: boolean): Promise<boolean> => {
    if (isNew) {
      try {
        const newService = await SaveActivity(service);
        const s = [...services];
        const index = s.findIndex((i) => i.id === service.id);
        s[index] = { ...newService };
        setServices(s);
        return true;
      } catch (e) {
        enqueueSnackbar("Failed to save service", { variant: "error" });
        console.log("Save error", e);
      }
    } else {
      console.log("Updating service", service);
      try {
        await UpdateActivity(service);
        return true;
      } catch (e) {
        enqueueSnackbar("Failed to save service", { variant: "error" });
        console.log("update error", e);
      }
    }
    return false;
  };

  const deleteService = async (service: Service) => {
    await DeleteActivity(service.id);
    enqueueSnackbar("Deleted service");
    setServices(services.filter((item) => item.id !== service.id));
  };

  return (
    <div>
      {isLoading ? (
        <LoadingView />
      ) : (
        <Paper className={classes.root}>
          {services.map((s: Service) => {
            return <ServiceCard key={s.id} service={s} saveService={saveService} deleteService={deleteService} />;
          })}
          {!services ||
            (services.length === 0 && (
              <div className={classes.noData}>
                <Typography variant="h6">You don't have any services yet</Typography>
                <Typography variant="subtitle1">Tap + to create one</Typography>
              </div>
            ))}
          <Fab color="secondary" aria-label="add" className={classes.addButton} onClick={addService}>
            <AddIcon />
          </Fab>
        </Paper>
      )}
    </div>
  );
};

export default Services;
