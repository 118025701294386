import { DataStore } from "aws-amplify";
import { Client, Invoice, Pet, ServiceRendered, Vet } from "../models";
import { format } from "date-fns";
import { Auth } from "aws-amplify";

export const saveClient = async (client: Client): Promise<Client> => {
  if (!client.id) {
    return await DataStore.save(
      new Client({
        ...client,
      })
    );
  } else {
    const original = await DataStore.query(Client, client.id);
    if (!original) {
      throw new Error("unable to find client to update");
    }
    console.log("updating", client);
    const update = await DataStore.save(
      Client.copyOf(original, (updated) => {
        Object.assign(updated, client);
      })
    );
    console.log("updated", update);
    return update;
  }

  // if (!client.id) {
  //     console.log("saving", client);
  //     const saveResult = (await API.graphql({ query: createClient, variables: { input: input } })) as { data: CreateClientMutation };
  //     console.log("save result", saveResult);

  //     if (saveResult.data.createClient) {
  //         return { ...client, id: saveResult.data.createClient.id };
  //     } else {
  //         throw (new Error("Failed to save client"));
  //     }
  // } else {
  //     console.log("updating", client);
  //     input.id = client.id;
  //     const updateResult = (await API.graphql({ query: updateClient, variables: { input: input } })) as { data: UpdateClientMutation };
  //     console.log("update result", updateResult);
  //     if (updateResult.data.updateClient) {
  //         return client;
  //     } else {
  //         throw (new Error("Failed to update client"));
  //     }
  // }
};

export const deleteClient = async (client: Client) => {
  console.log("Deleting", client);
  await DataStore.delete(client);

  // const deleteInput: DeleteClientInput = { id: client.id }
  // const deleteResult = await API.graphql({ query: deleteClientMutation, variables: { input: deleteInput } });
  // console.log(deleteResult);
};

export const loadClients = async (): Promise<Client[]> => {
  const clients = await DataStore.query(Client);
  clients.sort((a, b) => {
    const aValue = a.reference ?? a.surname;
    const bValue = b.reference ?? b.surname;

    return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
  });
  return clients;
  // const response = (await API.graphql({ query: listClients })) as { data: ListClientsQuery };
  // const clients = response.data?.listClients?.items?.map(i => ({
  //     ...i
  // } as Client)) || [];
  // return clients;
};

export const loadClientsForInvoicing = async (startDate: Date | null, endDate: Date | null): Promise<Invoice[]> => {
  const { attributes } = await Auth.currentAuthenticatedUser();
  if (!startDate || !endDate) {
    console.log("no dates provided");
    return [];
  }

  // Get all existing invoices
  const invoices = await DataStore.query(Invoice, (i) =>
    i
      .startDate("ge", format(startDate, "yyyy-MM-dd"))
      .endDate("le", format(endDate, "yyyy-MM-dd"))
      .owner("eq", attributes.sub)
  );

  const existingInvoices: Array<Invoice> = [];

  for (const i of invoices) {
    const serviceRenderedForInvoice = await DataStore.query(ServiceRendered, (s) => s.invoiceID("eq", i.id));
    const newInvoice: Invoice = { ...i, servicesRendered: serviceRenderedForInvoice };
    existingInvoices.push(newInvoice);
  }
  //console.log("existing invoices", existingInvoices)

  //end get all existing invoices

  //Get all services not attached to an invoice
  let servicesRendered = (
    await DataStore.query(ServiceRendered, (s) =>
      s.date("ge", format(startDate, "yyyy-MM-dd")).date("le", format(endDate, "yyyy-MM-dd"))
    )
  ).filter((s) => !s.invoiceID || s.invoiceID === "");
  //console.log("servicesRendred", servicesRendered)
  //End get all services not attached to an invoice

  // Make new invoices for each client that contain the service not attached to an invoice
  const completeInvoiceList: Array<Invoice> = [];

  const all = servicesRendered.reduce((prev, cur) => {
    if (prev.filter((i) => i.clientID === cur.client.id).length === 0) {
      const sr: Array<ServiceRendered> = [];
      sr.push(cur);
      prev.push(
        new Invoice({
          clientID: cur.client.id,
          subtotal: (cur.serviceCost ?? cur.service.cost) * cur.quantity + (cur.mileageCost ?? 0),
          startDate: cur.date,
          endDate: cur.date,
          servicesRendered: sr,
          invoiceName: "",
        })
      );
    } else {
      const index = prev.findIndex((i) => i.clientID === cur.client.id);
      let start = prev[index].startDate;
      if (!start || new Date(cur.date) < new Date(start)) {
        start = cur.date;
      }
      let end = prev[index].endDate;
      if (!end || new Date(cur.date) > new Date(end)) {
        end = cur.date;
      }
      const sr = Object.assign([], prev[index].servicesRendered);
      sr.push(cur);

      prev[index] = {
        ...prev[index],
        subtotal:
          (prev[index].subtotal ?? 0) + (cur.serviceCost ?? cur.service.cost) * cur.quantity + (cur.mileageCost ?? 0),
        startDate: start,
        endDate: end,
        servicesRendered: sr,
      };
    }
    return prev;
  }, completeInvoiceList);

  // const clients = await (await DataStore.query(Client));//.filter(c => all.find(i => i.clientID === c.id));
  // const clientsNewInvoices: Array<Client> = [];
  //  all.forEach(invoice => {
  //     const thisClient = clients.filter(c => c.id == invoice.clientID);
  //     if (thisClient && thisClient.length > 0){
  //         const client = thisClient[0];
  //         const cServices = servicesRendered.filter(s => s.client.id === client.id) ?? [];
  //         clientsNewInvoices.push({...client, invoices: [invoice], servicesUsed: cServices });
  //     }

  // });

  // console.log("clientsNewInvoices", clientsNewInvoices)

  // const clientsExistingInvoices: Array<Client> = [];
  //  existingInvoices.forEach(invoice => {
  //     const thisClient = clients.filter(c => c.id == invoice.clientID);
  //     if (thisClient && thisClient.length > 0){
  //         const client = thisClient[0];
  //         const cServices = servicesRendered.filter(s => s.invoiceID === invoice.id) ?? [];
  //         clientsExistingInvoices.push({...client, invoices: [invoice], servicesUsed: cServices });
  //     }

  // });

  // console.log("clientsExistingInvoices", clientsExistingInvoices)

  return [...all, ...existingInvoices];
};

export const getClient = async (clientId: string): Promise<Client> => {
  const result = (await DataStore.query(Client, (c) => c.id("eq", clientId)))[0];
  const pets = await DataStore.query(Pet, (p) => p.clientID("eq", clientId));
  const vets = await DataStore.query(Vet);
  const petsWithVets = pets.map((p) => {
    const vet = vets.filter((v) => v.id === p.vetID);
    if (vet && vet.length === 1) {
      return { ...p, vet: vet[0] };
    } else {
      return p;
    }
  });

  const activities = await (await DataStore.query(ServiceRendered)).filter((s) => s.client.id === clientId);
  const invoices = await (await DataStore.query(Invoice)).filter((i) => i.clientID === clientId);
  const client: Client = { ...result, pet: petsWithVets, servicesUsed: activities, invoices: invoices };
  console.log("client", client);
  return client;
  // const response = (await API.graphql(graphqlOperation(getClientQuery, { id: clientId }))) as { data: GetClientQuery };
  // const client: Client = { ...response.data.getClient, servicesUsed: response.data.getClient?.servicesUsed?.items, pet: response.data.getClient?.pet?.items } as Client;
  // console.log("getClient", client);

  // return client;
};
