import { Address } from "../Data/Types";

export const LookupAddress = async (postcode: string, address: string): Promise<Address | null> => {
  const url = `https://api.geoapify.com/v1/geocode/search?text=${address},%20${postcode}&limit=1&format=json&apiKey=6a22bda6bc77425c8de0a4e543afa2de`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
      const values = data?.results[0];
      if (values) {
        if (values.rank.confidence < 0.2) {
          return null;
        }
        const addr: Address = {
          address: address,
          town: values.city,
          county: values.county,
          postCode: postcode,
        };
        return addr;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
};
